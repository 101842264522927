import React, { useContext, useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";

import GifLoader from "./gifLoader";

import { GlobalContext } from "../../context/global_context";

import IconUI from "../ui/icon_ui";
import { GrClose } from "react-icons/gr";
import { RiSave3Fill, RiCloseFill } from "react-icons/ri";
import { BiPlus } from "react-icons/bi";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AiOutlineMinusCircle } from "react-icons/ai";
import {
  onlyNumberPhone,
  onlyCP,
  maxLengthLimitInput,
  onlyNumber,
} from "../../utils/inputsRules";

import OverLayIU from "./overlay_ui";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { services } from "../../services/api";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#6A0F49",
    // color: '#8d949e',
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#6A0F49",
    // borderBottomColor: '#8d949e',
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#6A0F49",
      // borderColor: '#8d949e',
    },
    "&:hover fieldset": {
      borderColor: "#6A0F49",
      // borderColor: '#8d949e',
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6A0F49",
      // borderColor: '#8d949e',
    },
  },
});

export default function PopUpOffice({ onclose }) {
  const { idDependencia } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const { infoOfi, setInfoOfi } = useContext(GlobalContext);

  const hiddenPopUp = () => {
    onclose();
  };

  const [state, setState] = useState({
    cp: "",
    catOficinas: [],
    catAsentamiento: [],
    catOfiAnt: [],
    catPrivacidad: [],
    catRedes: [],
    municipio: "",
  });

  useEffect(async () => {
    let isMounted = true;
    if (isMounted) {
      setState({ ...state, loading: true });
      let dataAPI, dataAPI2, dataAPI3, dataAPI4;
      dataAPI = await services("GET", `catalogos/tiposOficinas`);
      dataAPI2 = await services("GET", `catalogos/tiposPrivacidades`);
      dataAPI3 = await services(
        "GET",
        `catalogos/oficinasSuperior/${idDependencia}`
      );
      dataAPI4 = await services("GET", `catalogos/tiposRedes`);
      setState({
        ...state,
        catOficinas: dataAPI.data,
        catPrivacidad: dataAPI2.data,
        catOfiAnt: dataAPI3.data,
        catRedes: dataAPI4.data,
      });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const [addInput, setInput] = useState({
    nombre: "",
    siglas: "",
    ur: null,
    cat_oficina: 1,
    dependencia: idDependencia,
    oficina_ant: null,
    asentamiento: "",
    calle: "",
    num_ext: "",
    num_int: "",
    longitud: "19.69812709959231",
    latitud: "-101.17828343067352",
    paginas: [],
    emails: [""],
    horario: { inicio: null, fin: null },
    telefonos: [
      {
        numero: "",
        privacidad_telefono: "",
        extensiones: [
          {
            extension: "",
            privacidad: "",
          },
        ],
        marcaciones_cortas: [],
      },
    ],
  });

  const actualizarOficinas = async () => {
    setInfoOfi({ ...infoOfi, loading: true });
    setIsLoading(true);
    let dataAPI;
    if (idDependencia) {
      dataAPI = await services(
        "GET",
        `funcionarios/listarOficinas/${idDependencia}`
      );
    } else {
      dataAPI = await services(
        "GET",
        `funcionarios/listarOficinas/${idDependencia}`
      );
    }

    if (dataAPI.status === "success") {
      setIsLoading(false);
      setInfoOfi({ ...infoOfi, info: dataAPI.data, loading: false });
    }
    if (dataAPI.status === "info") {
      setIsLoading(false);
      setInfoOfi({ ...infoOfi, loading: false });
    }
  };

  const handleOffice = (event) => {
    setInput({ ...addInput, cat_oficina: event.target.value });
  };

  const handleEmails = (event, i) => {
    addInput.emails[i] = event.target.value;
    setInput({ ...addInput });
  };

  const handleOfficeAnt = (event) => {
    setInput({ ...addInput, oficina_ant: event.target.value });
  };

  const handleAsentamiento = (event) => {
    setInput({ ...addInput, asentamiento: event.target.value });
  };

  const handlePrivacyTelefono = (event, i) => {
    addInput.telefonos[i].privacidad_telefono = event.target.value;
    setInput({ ...addInput });
  };

  const handlePrivacyExtencion = (event, i, j) => {
    addInput.telefonos[i].extensiones[j].privacidad = event.target.value;
    setInput({ ...addInput });
  };
  const handlePrivacyPagina = (event, i) => {
    addInput.paginas[i].privacidad = event.target.value;
    setInput({ ...addInput });
  };

  const handleRedes = (event, i) => {
    addInput.paginas[i].red = event.target.value;
    setInput({ ...addInput });
  };

  const getAddress = async (CP) => {
    if (CP.length === 5) {
      const dataGetAddress = await services(
        "GET",
        `catalogos/tiposAsentamientos/${CP}`
      );
      setState({
        ...state,
        catAsentamiento: dataGetAddress.data[0].asentamientos,
        municipio: dataGetAddress.data[0].municipio,
        cp: CP,
      });
    } else {
      setState({ ...state, cp: CP });
    }
  };

  const handleHorarioInicio = (time) => {
    const { $H, $m } = time;
    setInput({
      ...addInput,
      horario: { ...addInput.horario, inicio: $H + ":" + $m },
    });
  };
  const handleHorarioFin = (time) => {
    const { $H, $m } = time;
    setInput({
      ...addInput,
      horario: { ...addInput.horario, fin: $H + ":" + $m },
    });
  };

  /* manejo de errores: principio */
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let newErrors = {};
    if (!addInput.nombre) {
      newErrors.nombre = "El campo nombre es requerido";
    }
    if (!addInput.cat_oficina) {
      newErrors.cat_oficina = "El campo Apellido Paterno es requerido";
    }
    if (addInput.emails) {
      const setDeEmails = new Set(addInput.emails);
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      addInput.emails.map((email) => {
        if (email === "") {
          newErrors.emails = "Al menos un Email debe ser ingresado";
        } else {
          let valido = email.match(regexEmail);
          if (!valido) {
            newErrors.emails = "Ingrese un email valido";
          }
        }
      });

      if (setDeEmails.size !== addInput.emails.length) {
        newErrors.emails = "No puede haber correos repetidos favor de revisar";
      }
    }

    if (addInput.telefonos) {
      addInput.telefonos.forEach((telefono) => {
        if (telefono.numero === "") {
          newErrors.telefonos = "Al menos un telefono debe ser ingresado";
        } else if (telefono.numero.length < 10) {
          newErrors.telefonos = "El telefono debe ser de 10 digitos";
        }
      });
    }
    // if(addInput.paginas){ addInput.paginas.forEach( (pagina) =>
    //     {
    //         if( pagina.url === "" ){
    //             newErrors.paginas = 'Al menos una pagina debe ser ingresada'
    //         }
    //     } )
    // }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  /* manejo de errores: fin */

  /* Manejo de POST: inicio */
  const handleSubmit = async () => {
    if (validateForm()) {
      postRequest();
    } else {
      Swal.fire({
        title: "Atención",
        text: "Faltan campos por rellenar",
        heightAuto: false,
        toast: true,
        timer: 3000,
        position: "bottom-end",
        showConfirmButton: false,
        confirmButtonColor: "#6A0F49",
        confirmButtonText: "ACEPTAR",
        icon: "warning",
      });
    }
  };

  const postRequest = async () => {
    setIsLoading(true);
    let response;
    if (!addInput.horario.inicio && !addInput.horario.fin) {
      response = await services("POST", "oficinas/nuevaOficina", {
        ...addInput,
        horario: null,
      });
    } else {
      response = await services("POST", "oficinas/nuevaOficina", addInput);
    }

    if (response.status === "success") {
      setIsLoading(false);
      Swal.fire({
        title: "Proceso completado",
        text: response.message,
        icon: "success",
        allowOutsideClick: false,
        confirmButtonColor: "#6A0F49",
        confirmButtonText: "ACEPTAR",
      }).then((result) => {
        if (result.isConfirmed) {
          hiddenPopUp();
          actualizarOficinas();
        }
      });
    } else if (response.status === 422) {
      setIsLoading(false);
      const concatenateValues = (response) => {
        if (response && response.data) {
          const values = Object.values(response.data);
          return Array.from(new Set(values.flat())).join("\n");
        }
        return "";
      };

      const concatenatedValues = concatenateValues(response);
      // "El funcionario ya se encuentra registrado."
      Swal.fire({
        title: "Atención",
        text: concatenatedValues,
        icon: "warning",
        confirmButtonColor: "#6A0F49",
        confirmButtonText: "ACEPTAR",
      });
    } else if (response.status === "info") {
      setIsLoading(false);
      Swal.fire({
        title: "Atención",
        text: response.message,
        icon: "warning",
        confirmButtonColor: "#6A0F49",
        confirmButtonText: "ACEPTAR",
      });
    }
  };
  const obtenerMensajes = (obj) => {
    let mensajes = "";

    Object.values(obj).forEach((value) => {
      if (typeof value === "object" && value !== null) {
        // Verificar si el valor es un objeto no nulo
        if ("message" in value) {
          mensajes += obtenerMensajes(value.message);
        } else {
          mensajes += obtenerMensajes(value);
        }
      } else if (Array.isArray(value)) {
        // Concatenar los elementos de un array
        mensajes += value.join(" ");
      } else {
        mensajes += value;
      }
    });

    return mensajes;
  };

  /* Manejo de POST: fin */

  return (
    <>
      <OverLayIU onClick={hiddenPopUp} />
      {isLoading ? (
        <GifLoader /> // Componente de carga
      ) : (
        <div className="wizard">
          <div className="d-flex justify-content-end ">
            <div className="cursor-pointer" onClick={hiddenPopUp}>
              <IconUI>
                <GrClose />
              </IconUI>
            </div>
          </div>

          <h3 className="text-bold">Añadir Oficina</h3>
          <div
            className="d-flex-inline justify-content-center "
            style={{ width: "80%", margin: "auto" }}
          >
            <div className="d-flex w-100">
              <CssTextField
                className="col-12"
                id="oficina"
                name="oficina"
                value={addInput.nombre}
                label="Nombre de la Oficina"
                size="small"
                margin="dense"
                error={!!errors.nombre}
                helperText={errors.nombre || ""}
                inputProps={{
                  maxLength: 100,
                }}
                onChange={(evt) => {
                  setInput({ ...addInput, nombre: evt.target.value });
                }}
                required
              />
            </div>

            <div className="d-flex flex-column flex-md-row justify-content-between">
              <CssTextField
                className="col-md col-sm-12 me-1"
                margin="dense"
                id="siglas"
                name="siglas"
                value={addInput.siglas}
                label="Siglas"
                size="small"
                onChange={(evt) => {
                  setInput({ ...addInput, siglas: evt.target.value });
                }}
              />
              <CssTextField
                className="col-md col-sm-12 me-1"
                margin="dense"
                id="oficina_ant"
                name="oficina_ant"
                value={addInput.oficina_ant}
                select
                label="Oficina del Jefe Superior"
                SelectProps={{
                  native: false,
                }}
                size="small"
                onChange={handleOfficeAnt}
                style={{ width: "100% !important" }}
                disabled={
                  !state.catOfiAnt ||
                  state.catOfiAnt === null ||
                  state.catOfiAnt[0]?.id === null
                }
              >
                {state.catOfiAnt &&
                  state.catOfiAnt.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.oficina}
                    </MenuItem>
                  ))}
              </CssTextField>

              <CssTextField
                required
                className="col-md col-sm-12"
                margin="dense"
                id="cat_oficina"
                name="cat_oficina"
                value={addInput.cat_oficina}
                error={!!errors.cat_oficina}
                helperText={errors.cat_oficina || ""}
                select
                label="Tipo de Oficina"
                SelectProps={{
                  native: false,
                }}
                size="small"
                onChange={handleOffice}
                style={{ width: "100% !important", textAlign: "left" }}
              >
                {state.catOficinas.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.tipo}
                  </MenuItem>
                ))}
              </CssTextField>
            </div>

            <div className="d-flex flex-column flex-md-row justify-content-between">
              <CssTextField
                className="col-md col-sm-12 me-1"
                id="cp"
                label="Código Postal"
                size="small"
                name="cp"
                margin="dense"
                value={state.cp}
                onKeyPress={onlyCP}
                onChange={(evt) => {
                  getAddress(evt.target.value);
                }}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  maxLength: 5,
                }}
              />

              <CssTextField
                className="col-md  col-sm-12 me-1"
                margin="dense"
                id="asentamiento"
                name="asentamiento"
                value={addInput.asentamiento}
                select
                label="Colonias"
                SelectProps={{
                  native: false,
                }}
                size="small"
                onChange={handleAsentamiento}
                style={{ width: "100% !important" }}
              >
                {state.catAsentamiento.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.asentamiento}
                  </MenuItem>
                ))}
              </CssTextField>

              <CssTextField
                className="col-md col-sm-12"
                id="municipio"
                name="municipio"
                label="Municipio"
                size="small"
                margin="dense"
                value={state.municipio}
              />
            </div>

            <div className="d-flex flex-column w-100">
              <CssTextField
                id="calle"
                name="calle"
                value={addInput.calle}
                label="Calle"
                size="small"
                margin="dense"
                onChange={(evt) => {
                  setInput({ ...addInput, calle: evt.target.value });
                }}
              />
            </div>

            <div className="d-flex justify-content-between">
              <CssTextField
                className="col-md me-1"
                id="num_ext"
                name="num_ext"
                value={addInput.num_ext}
                label="Número Exterior"
                size="small"
                margin="dense"
                onChange={(evt) => {
                  setInput({ ...addInput, num_ext: evt.target.value });
                }}
                onKeyPress={onlyNumber}
              />

              <CssTextField
                className="col-md "
                id="num_int"
                name="num_int"
                value={addInput.num_int}
                label="Número Interior"
                size="small"
                margin="dense"
                onChange={(evt) => {
                  setInput({ ...addInput, num_int: evt.target.value });
                }}
              />
            </div>

            <h5 className="text-bold">Horario</h5>

            <div className="d-flex justify-content-center">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                  onChange={handleHorarioInicio}
                  value={dayjs(
                    Date.parse("01 Jan 2024 " + addInput.horario.inicio + ":00")
                  )}
                  className="me-1"
                  renderInput={(props) => (
                    <CssTextField {...props} label="Inicio" />
                  )}
                />
                <MobileTimePicker
                  onChange={handleHorarioFin}
                  value={dayjs(
                    Date.parse("01 Jan 2024 " + addInput.horario.fin + ":00")
                  )}
                  renderInput={(props) => (
                    <CssTextField {...props} label="Fin" />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>

          {/* Boton de agregar telefonos */}

          <div className=" d-flex mt-1">
            <button
              className=" pill pill-little"
              onClick={() => {
                addInput.telefonos.push({
                  numero: "",
                  privacidad_telefono: "",
                  extensiones: [
                    {
                      extension: "",
                      privacidad: "",
                    },
                  ],
                  marcaciones_cortas: [""],
                });
                setInput({ ...addInput });
              }}
            >
              <IconUI size={20}>
                <BiPlus />
              </IconUI>
              Agregar Telefono
            </button>
          </div>

          {/* Arreglo de Telefonos, extenciones y marcaciones cortas */}
          <div
            className="d-flex flex-column "
            style={{ width: "80%", margin: "auto" }}
          >
            <div className="d-flex flex-column align-self-start ">
              {addInput.telefonos.map((telefono, index) => {
                return (
                  <Fragment key={index}>
                    <div className="d-flex">
                      <div
                        className="d-flex text-guinda me-2"
                        style={{ width: "150px" }}
                      >
                        <CssTextField
                          className="col"
                          margin="dense"
                          id="outlined-select-currency-native"
                          select
                          label="Privacidad"
                          SelectProps={{
                            native: false,
                          }}
                          size="small"
                          onChange={(event) => {
                            handlePrivacyTelefono(event, index);
                          }}
                          style={{ width: "100% !important" }}
                        >
                          {state.catPrivacidad.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.tipo}
                            </MenuItem>
                          ))}
                        </CssTextField>
                      </div>

                      <div
                        className="d-flex flex-column me-2"
                        style={{ width: "300px" }}
                      >
                        <CssTextField
                          id={`telefono_${index}`}
                          name={`telefono_${index}`}
                          value={addInput.telefonos[index].numero}
                          error={!!errors.telefonos}
                          helperText={errors.telefonos || ""}
                          label="Teléfono (10 Dígitos)"
                          size="small"
                          margin="dense"
                          onChange={(evt) => {
                            addInput.telefonos[index].numero =
                              evt.currentTarget.value;
                            setInput({ ...addInput });
                          }}
                          onKeyPress={onlyNumberPhone}
                          inputProps={{
                            maxLength: 10,
                          }}
                        />
                      </div>

                      <div className=" d-flex m-1 ">
                        <button
                          className=" pill pill-little"
                          onClick={() => {
                            addInput.telefonos[index].extensiones.push({
                              extension: "",
                              privacidad: "",
                            });
                            setInput({ ...addInput });
                          }}
                        >
                          <IconUI size={20}>
                            <BiPlus />
                          </IconUI>
                          Extensión
                        </button>
                      </div>

                      <div
                        className=" d-flex m-1"
                        onClick={() => {
                          addInput.telefonos[index].marcaciones_cortas.push("");
                          setInput({ ...addInput });
                        }}
                      >
                        <button className=" pill pill-little">
                          <IconUI size={20}>
                            <BiPlus />
                          </IconUI>
                          M.C
                        </button>
                      </div>

                      {/* Quitar todo el arreglo de numeros */}
                      <div
                        className=" d-flex m-1 ms-3"
                        onClick={() => {
                          addInput.telefonos.splice(index, 1);
                          setInput({ ...addInput });
                        }}
                      >
                        <IconUI size={"30px"} color={"#6A0F49"}>
                          <AiOutlineMinusCircle />
                        </IconUI>
                      </div>
                    </div>

                    {/* Inputs Extenciones */}
                    <div className="d-flex flex-column ">
                      {addInput.telefonos[index].extensiones.map(
                        (extension, j) => {
                          return (
                            <div className="d-flex" key={j}>
                              <div
                                className="d-flex text-guinda me-2"
                                style={{ width: "150px" }}
                              >
                                <CssTextField
                                  className="col"
                                  margin="dense"
                                  id="outlined-select-currency-native"
                                  select
                                  label="Privacidad"
                                  SelectProps={{
                                    native: false,
                                  }}
                                  size="small"
                                  onChange={(event) => {
                                    handlePrivacyExtencion(event, index, j);
                                  }}
                                  style={{ width: "100% !important" }}
                                >
                                  {state.catPrivacidad.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.tipo}
                                    </MenuItem>
                                  ))}
                                </CssTextField>
                              </div>
                              <div
                                className="d-flex flex-column me-2"
                                style={{ width: "300px" }}
                              >
                                <CssTextField
                                  id="outlined-select-currency-native"
                                  label="Extensión"
                                  size="small"
                                  margin="dense"
                                  value={
                                    addInput.telefonos[index].extensiones[j]
                                      .extension
                                  }
                                  onChange={(evt) => {
                                    addInput.telefonos[index].extensiones[
                                      j
                                    ].extension = evt.currentTarget.value;
                                    setInput({ ...addInput });
                                  }}
                                  inputProps={{
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                    maxLength: 5,
                                  }}
                                  onKeyPress={onlyNumber}
                                />
                              </div>

                              <div
                                className=" d-flex mt-1"
                                onClick={() => {
                                  addInput.telefonos[index].extensiones.splice(
                                    j,
                                    1
                                  );
                                  setInput({ ...addInput });
                                }}
                              >
                                <IconUI size={"30px"} color={"#6A0F49"}>
                                  <AiOutlineMinusCircle />
                                </IconUI>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>

                    {/* Inputs Marcaciones Cortas */}

                    <div className="d-flex flex-column">
                      {addInput.telefonos[index].marcaciones_cortas.map(
                        (marcacion, k) => {
                          return (
                            <div className="d-flex " key={k}>
                              <div
                                className="d-flex flex-column me-2"
                                style={{ width: "300px" }}
                              >
                                <CssTextField
                                  id="outlined-select-currency-native"
                                  label="Marcación Corta"
                                  size="small"
                                  margin="dense"
                                  value={
                                    addInput.telefonos[index]
                                      .marcaciones_cortas[k]
                                  }
                                  onChange={(evt) => {
                                    addInput.telefonos[
                                      index
                                    ].marcaciones_cortas[k] =
                                      evt.currentTarget.value;
                                    setInput({ ...addInput });
                                  }}
                                  inputProps={{
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                    maxLength: 5,
                                  }}
                                  onKeyPress={onlyNumber}
                                />
                              </div>

                              <div
                                className=" d-flex mt-1"
                                onClick={() => {
                                  addInput.telefonos[
                                    index
                                  ].marcaciones_cortas.splice(k, 1);
                                  setInput({ ...addInput });
                                }}
                              >
                                <IconUI size={"30px"} color={"#6A0F49"}>
                                  <AiOutlineMinusCircle />
                                </IconUI>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>

          {/* Boton de Emails */}

          <div className=" d-flex mt-1">
            <button
              className=" pill pill-little"
              onClick={() => {
                const inputs = addInput.emails;
                inputs.push("");
                setInput({ ...addInput, emails: inputs });
              }}
            >
              <IconUI size={20}>
                <BiPlus />
              </IconUI>
              Agregar Email
            </button>
          </div>

          {/* Arreglo de emails */}

          <div
            className="d-flex flex-column align-items-start"
            style={{ width: "80%", margin: "auto" }}
          >
            {addInput.emails &&
              addInput.emails.map((item, index) => {
                return (
                  <div
                    className="d-flex align-self-start d-flex justify-content-around"
                    key={index}
                  >
                    <div
                      className="d-flex flex-column  me-2 w-80"
                      style={{ width: "470px" }}
                    >
                      <CssTextField
                        required
                        id={`email_${index}`}
                        name={`email_${index}`}
                        label="Correo"
                        size="small"
                        margin="dense"
                        value={item ?? ""}
                        error={!!errors.emails}
                        helperText={errors.emails || ""}
                        inputProps={{
                          maxLength: 100,
                        }}
                        onChange={(e) => handleEmails(e, index)}
                        onKeyPress={(e) => maxLengthLimitInput(e, 100, "email")}
                      />
                    </div>

                    <div
                      className="w-10 d-flex mt-1"
                      onClick={() => {
                        const inputs = addInput.emails;

                        inputs.pop(1);
                        setInput({ ...addInput, emails: [...inputs] });
                      }}
                    >
                      {
                        <IconUI size={"30px"} color={"#6A0F49"}>
                          <AiOutlineMinusCircle />
                        </IconUI>
                      }
                    </div>
                  </div>
                );
              })}
          </div>

          {/* Boton de paginas */}

          <div className=" d-flex mt-1">
            <button
              className=" pill pill-little"
              onClick={() => {
                addInput.paginas.push({
                  url: "",
                  red: "",
                  privacidad: "",
                });
                setInput({ ...addInput });
              }}
            >
              <IconUI size={20}>
                <BiPlus />
              </IconUI>
              Agregar Pagina
            </button>
          </div>

          {/* Arreglo de paginas */}

          <div
            className="d-flex flex-column "
            style={{ width: "80%", margin: "auto" }}
          >
            {addInput.paginas.map((item, i) => {
              return (
                <div className="d-flex align-self-start" key={i}>
                  <div
                    className="d-flex flex-column  me-2"
                    style={{ width: "30%" }}
                  >
                    <CssTextField
                      className="col-md"
                      margin="dense"
                      id="outlined-select-currency-native"
                      select
                      label="Privacidad"
                      SelectProps={{
                        native: false,
                      }}
                      size="small"
                      style={{ width: "100% !important" }}
                      onChange={(e) => handlePrivacyPagina(e, i)}
                    >
                      {state.catPrivacidad.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.tipo}
                        </MenuItem>
                      ))}
                    </CssTextField>
                  </div>

                  <div
                    className="d-flex flex-column  me-2"
                    style={{ width: "30%" }}
                  >
                    <CssTextField
                      className="col-md"
                      margin="dense"
                      id="outlined-select-currency-native"
                      select
                      label="Redes"
                      SelectProps={{
                        native: false,
                      }}
                      size="small"
                      onChange={(e) => handleRedes(e, i)}
                      style={{ width: "100% !important" }}
                    >
                      {state.catRedes.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.tipo}
                        </MenuItem>
                      ))}
                    </CssTextField>
                  </div>
                  <div
                    className="d-flex flex-column  me-2"
                    style={{ width: "470px" }}
                  >
                    <CssTextField
                      id={`pagina_${i}`}
                      name={`pagina_${i}`}
                      label="Pagina Web"
                      size="small"
                      margin="dense"
                      value={addInput.paginas[i].url}
                      error={!!errors.paginas}
                      helperText={errors.paginas || ""}
                      onChange={(evt) => {
                        addInput.paginas[i].url = evt.currentTarget.value;
                        setInput({ ...addInput });
                      }}
                    />
                  </div>

                  <div
                    className=" d-flex mt-1"
                    onClick={() => {
                      addInput.paginas.splice(i, 1);
                      setInput({ ...addInput });
                    }}
                  >
                    <IconUI size={"30px"} color={"#6A0F49"}>
                      <AiOutlineMinusCircle />
                    </IconUI>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Botones de Cancelar y Guardar */}
          <div className="d-flex justify-content-center">
            <button className="pill-red" onClick={hiddenPopUp}>
              <IconUI size={25}>
                <RiCloseFill />
              </IconUI>
              <h6 className="pt-2">Cancelar</h6>
            </button>

            <button className="pill-green " onClick={handleSubmit}>
              <IconUI size={25}>
                <RiSave3Fill />
              </IconUI>
              <h6 className="pt-2">Guardar</h6>
            </button>
          </div>
        </div>
      )}
    </>
  );
}
