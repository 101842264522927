import React, { useState } from 'react'
// import { useHistory } from 'react-router-dom';
import { RiSendPlaneFill, RiArrowGoBackFill } from 'react-icons/ri';
import IconUI from '../components/ui/icon_ui'
import Swal from 'sweetalert2';
import { services } from '../services/api';


export const RecoveryPassView = () => {
    // const history = useHistory()

    const [state, setState] = useState({
        email: ''
    })

    const recoveryPass = async () => {

        if (state.email === '') {
            Swal.fire({
                title: "Atención",
                text: 'Los campos no deben estar vacios',
                heightAuto: false,
                toast: true,
                timer: 3000,
                position: 'bottom-end',
                showConfirmButton: false,
                confirmButtonColor: '#6A0F49',
                confirmButtonText: 'ACEPTAR',
                icon: "warning"
            })
        } else {
            const dataRecovery = await services("POST", "user/recuperarPassword", state)
            if (dataRecovery.status === "success") {
                Swal.fire({
                    title: "Atención",
                    heightAuto: false,
                    text: dataRecovery.message,
                    icon: "success",
                    confirmButtonColor: '#6A0F49',
                    confirmButtonText: 'ACEPTAR'
                })
            } else {
                Swal.fire({
                    title: "Atención",
                    text: 'No existe este un usuario registrado a este correo',
                    heightAuto: false,
                    toast: true,
                    timer: 3000,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    confirmButtonColor: '#6A0F49',
                    confirmButtonText: 'ACEPTAR',
                    icon: "warning"
                })
            }
        }
    }




    return (
        <div>
            <div className='d-flex justify-content-center'>
                <div className='mt-5 justify-content-center '>
                    <h3 className='pt-5 text-gray'> Recuperar Contraseña</h3>
                    <div className='text-center'>
                        Ingrese su Correo Institucional
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <input className='form-input text text-center ' placeholder="Correo" onChange={(evt) => {
                    setState({ ...state, email: evt.target.value })
                }} />
            </div>

            <div className='d-flex justify-content-center'>
                {/* <button className='pill-blue' onClick={() => {
                    history.push(`/login`)
                }}>
                    Regresar
                    <IconUI size={25}>
                        <RiArrowGoBackFill />
                    </IconUI>
                </button> */}
                <button className='pill' onClick={recoveryPass}>
                    Enviar
                    <IconUI size={25}>
                        <RiSendPlaneFill />
                    </IconUI></button>
            </div>

        </div >
    )
}
