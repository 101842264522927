

export const Error500 = () => {

    return (

        <div className='d-flex justify-content-center align-items-center vh-100'>
            Error 500
        </div>

    );
}



