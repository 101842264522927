import { useContext, useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';

import { services } from '../services/api';
import { GlobalContext } from '../context/global_context';

import { BiSearch } from 'react-icons/bi';
import { AiOutlinePlus, AiFillEdit, AiTwotoneDelete } from 'react-icons/ai';
import { BsTelephoneFill, BsTelephone } from 'react-icons/bs';
import IconUI from '../components/ui/icon_ui';
import { generateKey } from '../utils/inputsRules';

import { useHistory } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import GifLoader from "../components/ui/gifLoader"
import Swal from 'sweetalert2';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import PopUpOffice from '../components/ui/popUpOffice';
import PopUpEditOffice from '../components/ui/popUpEditOffice';
import BackArrow from '../components/ui/backArrow';


export function OfficeAdminView() {

    const { idDependencia } = useParams()

    const { idDep, setIdOfi } = useContext(GlobalContext)

    const { infoOfi, setInfoOfi } = useContext(GlobalContext)

    const history = useHistory()
    useEffect(async () => {

        let isMounted = true
        if (isMounted) {
            setInfoOfi({ ...infoOfi, loading: true })
            let dataAPI;

            dataAPI = await services("GET", `funcionarios/listarOficinas/${idDependencia}`)

            if (dataAPI.status === "success") {
                setInfoOfi({ ...infoOfi, info: dataAPI.data, loading: false })
            }
            if (dataAPI.status === "info") {
                setInfoOfi({ ...infoOfi, loading: false })

            }

        }

        return () => {
            isMounted = false
        }
    }, [])


    const columns = [
        { id: 'oficina', label: 'Oficina', minWidth: 100 },
        { id: 'titular', label: 'Titular', minWidth: 100 },
        { label: 'Teléfono', minWidth: 100 },
        { label: 'Marcación Corta', minWidth: 100 },
        { label: 'Extensiones', minWidth: 100 },
        { label: 'Editar', minWidth: 100 },
        { label: 'Eliminar', minWidth: 100 },
    ];

    const rows = infoOfi.info;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const [stateFilter, setStateFilter] = useState({
        filtro: '',
        data: rows,
    })


    const filter = () => {
        if (stateFilter.filtro === '') {
            return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).filter((item) => {
                return item.oficina && item.oficina.toLowerCase().includes(stateFilter.filtro.toLowerCase())
                    || item.titular && item.titular.toLowerCase().includes(stateFilter.filtro.toLowerCase())
                    || item.telefono && item.telefono[0].numero && item.telefono[0].numero.toLowerCase().includes(stateFilter.filtro.toLowerCase())
                    || item.telefono && item.telefono[0].extensiones && item.telefono[0].extensiones.filter((item2) => {
                        return item2 && item2.includes(stateFilter.filtro.toLowerCase()) ?
                            item2
                            :
                            false
                    }).includes(stateFilter.filtro.toLowerCase())
                    || item.telefono && item.telefono[0].marcacion_corta && item.telefono[0].marcacion_corta.filter((item3) => {
                        return item3 && item3.includes(stateFilter.filtro.toLowerCase()) ?
                            item3
                            :
                            false
                    }).includes(stateFilter.filtro.toLowerCase())
            })
        }
        else {
            return rows.slice(page * rowsPerPage, rows.length).filter((item) => {
                return item.oficina && item.oficina.toLowerCase().includes(stateFilter.filtro.toLowerCase())
                    || item.titular && item.titular.toLowerCase().includes(stateFilter.filtro.toLowerCase())

                    || item.telefono && item.telefono[0].numero && item.telefono[0].numero.includes(stateFilter.filtro)
                    || item.telefono && item.telefono[1] && item.telefono[1].numero && item.telefono[1].numero.includes(stateFilter.filtro)
                    || item.telefono && item.telefono[2] && item.telefono[2].numero && item.telefono[2].numero.includes(stateFilter.filtro)
                    || item.telefono && item.telefono[3] && item.telefono[3].numero && item.telefono[3].numero.includes(stateFilter.filtro)
                    // || item.telefono && item.telefono[2].numero && item.telefono[2].numero.includes(stateFilter.filtro)
                    // || item.telefono && item.telefono[3].numero && item.telefono[3].numero.includes(stateFilter.filtro)
                    // || item.telefono && item.telefono[4].numero && item.telefono[4].numero.includes(stateFilter.filtro)

                    || item.telefono && item.telefono[0].extensiones && item.telefono[0].extensiones.filter((item2) => {
                        return item2 
                    }).includes(stateFilter.filtro.toLowerCase())

                    || item.telefono && item.telefono[1] && item.telefono[1].extensiones && item.telefono[1].extensiones.filter((item4) => {
                        return item4 
                    }).includes(stateFilter.filtro.toLowerCase())

                    || item.telefono && item.telefono[0].marcacion_corta && item.telefono[0].marcacion_corta.filter((item3) => {
                        return item3 && item3.includes(stateFilter.filtro.toLowerCase()) ?
                            item3
                            :
                            false
                    }).includes(stateFilter.filtro.toLowerCase())
            })
        }
    }

    const deleteOffice = (idOficina) => {
        Swal.fire({
            title: '¿Esta seguro de eliminar Oficina?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#6A0F49',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: '¡Si, eliminar!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await services("DELETE", `oficinas/eliminaOficina/${idOficina}`)
                if (response.status === "success") {
                    /* Logica de eliminacion */
                    var index = infoOfi.info.map(x => {
                        return x.id;
                    }).indexOf(idOficina);

                    infoOfi.info.splice(index, 1);
                    setInfoOfi({ ...infoOfi, info: infoOfi.info })

                    Swal.fire(
                        {
                            title: '¡Eliminado!',
                            icon: 'success',
                            text: 'La oficina fue eliminada con exito',
                            confirmButtonColor: '#6A0F49',
                            confirmButtonText: 'ACEPTAR'
                        }
                    )
                } else {
                    Swal.fire(
                        '¡Error!',
                        `${response.message}`,
                        'success'
                    )
                }
            }
        })
    }

    const [modal, setModal] = useState(false)
    const showModal = () => {
        setModal(true)
        window.scrollTo({
            top: 0,
            behavior: 'auto' // Opcional: agrega un desplazamiento suave
        });
    }

    const hiddenModal = () => {
        setModal(false)
    }

    const [modal2, setModal2] = useState(false)
    const showModal2 = (id) => {
        setIdOfi(id)
        setModal2(true)
        window.scrollTo({
            top: 0,
            behavior: 'auto' // Opcional: agrega un desplazamiento suave
        });
    }

    const hiddenModal2 = () => {
        setModal2(false)
    }



    return (
        <>
            {infoOfi.loading === true ?
                <GifLoader />
                :
                <div id="office" className='office'>
                    <div className="container text-center ">
                        <div className="d-block justify-content-center">
                            <div className="pt-3">
                                <h2 className="text-center text-large text-secondary">
                                    Bienvenido al Directorio Estatal de Servidores Públicos
                                </h2>
                            </div>
                            <div className="py-4">
                                <h6 className="text-center text-large text-tertiary">
                                    Por favor ingresa oficina, nombre del titular, teléfono o extensión de la de la oficina
                                </h6>
                            </div>
                            <div>
                                <div className="d-inline-flex pb-4 justify-content-center input-group mb-3 text-center ">

                                    <Input
                                        className='form-input text-center'
                                        type='text'
                                        endAdornment={<InputAdornment position="end">
                                            <IconUI size={'31px'}>
                                                <BiSearch />
                                            </IconUI>
                                        </InputAdornment>}
                                        value={stateFilter.filtro} onChange={(e) => {
                                            setStateFilter({ ...stateFilter, filtro: e.target.value })

                                        }
                                        }
                                        disableUnderline
                                    />
                                </div>
                                {
                                    filter().length <= 0 &&
                                    <div className='w-100 justify-content-center d-flex'>
                                        <div className='cta w-100 text-morado'>
                                            <div>
                                                {stateFilter.filtro.length > 0 && (<>No se encontraron resultados para</>)}
                                                <br />
                                                {`${stateFilter.filtro.length > 30 ? stateFilter.filtro.slice(0, 30) : stateFilter.filtro}`}
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className='d-flex justify-content-end ms-3'>
                                    <IconUI size={'30px'}>
                                        <AiOutlinePlus className='text-morado' />
                                    </IconUI>
                                    <h4 className='mt-2 text-morado text-decoration-underline cursor-pointer' onClick={showModal}>Añadir Oficina</h4>
                                </div>
                                {
                                    modal === true ?
                                        <PopUpOffice onclose={hiddenModal}>
                                        </PopUpOffice>
                                        :
                                        null
                                }

                                <div>
                                    <TableContainer  >
                                        <Table className='table table-striped'  >
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={generateKey()}
                                                            align="center"
                                                            style={{ top: 57, minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filter().map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={generateKey()} >
                                                            {columns.map((column, i) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <Fragment key={generateKey()} >
                                                                        {
                                                                            column.label === 'Teléfono' ?
                                                                                <TableCell align="center" key={column.id}>
                                                                                    {
                                                                                        row.telefono && row.telefono.map((item, i) => {

                                                                                            return (
                                                                                                item.numero.length < 1 ?
                                                                                                    <div key={generateKey()}>
                                                                                                        -
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div key={generateKey()}>
                                                                                                        <BsTelephoneFill className='text-morado me-1' />
                                                                                                        {item.numero + ''}
                                                                                                    </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </TableCell>
                                                                                :
                                                                                column.label === 'Marcación Corta' ?
                                                                                    <TableCell align="center" key={column.id}>
                                                                                        <div>
                                                                                            {
                                                                                                row.telefono && row.telefono.map((item, i) => {
                                                                                                    return (
                                                                                                        item.marcacion_corta.length < 1 ?
                                                                                                            <div key={generateKey()}>
                                                                                                                -
                                                                                                            </div>

                                                                                                            :
                                                                                                            <div key={generateKey()}>
                                                                                                                <BsTelephone className='text-morado me-1' />
                                                                                                                {'\n' + item.marcacion_corta}
                                                                                                            </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </TableCell>
                                                                                    :
                                                                                    column.label === 'Extensiones' ?

                                                                                        <TableCell align="center" key={column.id}>
                                                                                            <div>
                                                                                                {
                                                                                                    row.telefono && row.telefono.map((item, i) => {
                                                                                                        return (
                                                                                                            item.extensiones.length < 1 ?
                                                                                                                <div key={generateKey()}>
                                                                                                                    -
                                                                                                                </div>
                                                                                                                :
                                                                                                                <div key={generateKey()}>
                                                                                                                    <BsTelephone className='text-morado me-1' />
                                                                                                                    {'\n ' + item.extensiones}
                                                                                                                </div>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </TableCell>
                                                                                        :
                                                                                        column.label === 'Editar' ?

                                                                                            <TableCell align="center" key={column.id}>
                                                                                                <div className='cursor-pointer' onClick={() => { showModal2(row.id) }}>
                                                                                                    <IconUI size={'25px'}>
                                                                                                        <AiFillEdit className='text-morado' />
                                                                                                    </IconUI>

                                                                                                </div>
                                                                                            </TableCell>
                                                                                            :

                                                                                            column.label === 'Eliminar' ?

                                                                                                <TableCell align="center" key={column.id}>
                                                                                                    <div className='cursor-pointer' onClick={() => {

                                                                                                        deleteOffice(row.id)

                                                                                                    }} >
                                                                                                        <IconUI size={'25px'}>
                                                                                                            <AiTwotoneDelete className='text-morado' />
                                                                                                        </IconUI>

                                                                                                    </div>
                                                                                                </TableCell>

                                                                                                :
                                                                                                <TableCell className='cursor-pointer' key={column.id} align="center" onClick={() => {
                                                                                                    setIdOfi(row.id)
                                                                                                    history.push(`/administradorServidorPublico/${row.id}`)
                                                                                                }} >
                                                                                                    {column.format && typeof value === 'number'
                                                                                                        ? column.format(value)
                                                                                                        : value}
                                                                                                </TableCell>
                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                                {/* {modal2 === true ?
                                                    <PopUpEditOffice onclose={hiddenModal2}>
                                                    </PopUpEditOffice>
                                                    :
                                                    null} */}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 50, { label: 'Todas', value: -1 }]}
                                        component="div"
                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        labelRowsPerPage='Filas por Página:'
                                    />
                                </div>
                                {modal2 === true ?
                                    <PopUpEditOffice onclose={hiddenModal2}>
                                    </PopUpEditOffice>
                                    :
                                    null}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}