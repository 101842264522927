import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const NoFoundRoute_View = () => {
    const history = useHistory()

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            history.replace("/")
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className="container d-flex justify-content-center align-items-center vh-100">
            <div className="card">
                <div className="text-guinda text-center">Error 404 </div>
            </div>
        </div>
    )
}

export default NoFoundRoute_View