import LogoGris from "../assets/img/LogoGris.png";
import Sello from "../assets/img/Sello.png";
// import Sello from '../assets/img/SelloGris.png'
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineInstagram, AiOutlineWhatsApp } from "react-icons/ai";
import IconUI from "./ui/icon_ui";

export const Footer = () => {
  return (
    <div
      id="footer"
      className="footer footerEstilos shadow-sm bg-white border-top--rosa margin-on-search"
    >
      <div className="container-xxl footer-container py-3">
        <div className="inside-footer-container container-xxl d-md-flex d-flex flex-row flex-nowrap align-items-center justify-content-center">
          <div className="logos-container col-4 col-md-4 d-flex d-row flex-nowrap align-items-center justify-content-start">
            <img
              className="logoGris col-3 col-sm-8 col-md-10 h-50"
              src={LogoGris}
              alt="logo"
            />
            <img
              className="logoSello col-2 col-sm-3 col-md-2 ms-3"
              src={Sello}
              alt="logo"
            />
          </div>
          {/* <div className='col-md-4 col-4 d-flex d-column flex-wrap align-items-center justify-content-center'>                        
                    </div> */}
          <div className="col-md-8 col-8 d-flex flex-column align-items-end justify-content-center">
            <div className="contenedor-hashtag text-gray my-2 col-8 col-md-4 text-wrap">
              #HonestidadyTrabajo
            </div>
          </div>
        </div>
      </div>
      <div className="backgrounPlaca w-100"></div>
    </div>
  );
};
