import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { services } from "../services/api";
import Swal from "sweetalert2";
import { GlobalContext } from "../context/global_context";
import { BiLogIn } from "react-icons/bi";

export const LoginView = () => {
  const history = useHistory();
  const { setIcon, setIconAd } = useContext(GlobalContext);

  const [body, setBody] = useState({
    email: "",
    password: "",
  });

  const login = async () => {
    if (body.email === "" || body.password === "") {
      Swal.fire({
        title: "Atención",
        text: "Los campos no deben estar vacios",
        heightAuto: false,
        toast: true,
        timer: 3000,
        position: "bottom-end",
        showConfirmButton: false,
        confirmButtonColor: "#6A0F49",
        confirmButtonText: "ACEPTAR",
        icon: "warning",
      });
    } else if (body.password.length < 6) {
      Swal.fire({
        title: "Atención",
        text: "El campo contraseña debe contener al menos 6 caracteres",
        heightAuto: false,
        toast: true,
        timer: 3000,
        position: "bottom-end",
        showConfirmButton: false,
        confirmButtonColor: "#6A0F49",
        confirmButtonText: "ACEPTAR",
        icon: "warning",
      });
    } else {
      //peticion al enpoint
      const dataLogin = await services("POST", "user/login", body);

      if (dataLogin.status === "success") {
        //obtenemos el token
        const token = dataLogin.data.token;

        //decodificamos el token
        let decoded = jwt_decode(token);

        if (decoded.user_type === "admin") {
          localStorage.setItem("nombre", JSON.stringify(dataLogin.data.user));
          localStorage.setItem("token", dataLogin.data.token);
          history.push("/administradorDependencias");
          setIconAd(true);
        } else if (decoded.user_type === "funcionario") {
          localStorage.setItem("nombre", JSON.stringify(dataLogin.data.user));
          localStorage.setItem("token", dataLogin.data.token);
          history.push("/inicioFuncionario");
          setIcon(true);
        }
      } else if (dataLogin.status === "info") {
        Swal.fire({
          title: "Atención",
          heightAuto: false,
          text: dataLogin.message,
          icon: "warning",
          confirmButtonColor: "#6A0F49",
          confirmButtonText: "ACEPTAR",
        });
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const token = localStorage.getItem("token");
      if (token) {
        let decoded = jwt_decode(token);
        if (decoded.user_type === "admin") {
          history.push("/administradorDependencias");
        } else if (decoded.user_type === "funcionario") {
          history.push("/inicioFuncionario");
        }
      }
    }
    const keyDownHandler = (event) => {
      const btn = document.getElementById("btnLog");

      if (event.key === "Enter") {
        event.preventDefault();

        // 👇️ your logic here
        btn.click();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-center">
        <div className="mt-5 justify-content-center ">
          <h3 className="pt-5 text-gray"> ¡BIENVENIDO!</h3>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <input
          id="email"
          className="form-input text text-center"
          placeholder="Usuario"
          onChange={(evt) => {
            setBody({ ...body, email: evt.target.value });
          }}
        />
      </div>
      <div className="d-flex justify-content-center">
        <input
          id="pass"
          className="form-input text text-center"
          type="password"
          placeholder="Contraseña"
          onChange={(evt) => {
            setBody({ ...body, password: evt.target.value });
          }}
        />
      </div>
      <div className="d-flex justify-content-center">
        <button id="btnLog" className=" pill" onClick={login}>
          Entrar
          <BiLogIn style={{ height: 25, width: 25 }} className="text-guinda" />
        </button>
        <button
          id="btnLog"
          className=" pill-blue"
          onClick={() => {
            history.push("/recuperarContraseña");
          }}
        >
          Recuperar Contraseña
        </button>
      </div>
    </div>
  );
};
