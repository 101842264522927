import React, { useContext, useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import { GlobalContext } from "../../context/global_context";

import IconUI from "./icon_ui";
import { GrClose } from "react-icons/gr";
import { RiSave3Fill, RiCloseFill, RiXingLine } from "react-icons/ri";
import { BiPlus } from "react-icons/bi";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import Swal from "sweetalert2";
import GifLoader from "./gifLoader";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AiOutlineMinusCircle } from "react-icons/ai";
import {
  onlyCP,
  onlyNumber,
  onlyNumberPhone,
  maxLengthLimitInput,
} from "../../utils/inputsRules";

import OverLayIU from "./overlay_ui";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { services } from "../../services/api";
import { EventTwoTone } from "@mui/icons-material";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#6A0F49",
    // color: '#8d949e',
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#6A0F49",
    // borderBottomColor: '#8d949e',
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#6A0F49",
      // borderColor: '#8d949e',
    },
    "&:hover fieldset": {
      borderColor: "#6A0F49",
      // borderColor: '#8d949e',
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6A0F49",
      // borderColor: '#8d949e',
    },
  },
});

export default function PopUpEditOffice({ onclose }) {
  const { idOfi } = useContext(GlobalContext);
  const { infoOfi, setInfoOfi } = useContext(GlobalContext);
  const { idDependencia } = useParams();
  const [value, setValue] = useState(dayjs("2018-01-01T00:00:00.000Z"));

  const [isLoading, setIsLoading] = useState(false);

  const hiddenPopUp = () => {
    onclose();
  };

  const [state, setState] = useState({
    cp: "",
    catOficinas: [],
    catAsentamiento: [],
    catOfiAnt: [],
    catPrivacidad: [],
    catRedes: [],
    municipio: "",
  });

  useEffect(async () => {
    let isMounted = true;
    setIsLoading(true);
    if (isMounted) {
      setState({ ...state, loading: true });
      let dataAPI, dataAPI2, dataAPI3, dataAPI4, dataAPI5, dataAPI6;
      dataAPI = await services("GET", `catalogos/tiposOficinas`);
      dataAPI2 = await services("GET", `catalogos/tiposPrivacidades`);
      dataAPI3 = await services(
        "GET",
        `catalogos/oficinasSuperior/${idDependencia}`
      );
      dataAPI4 = await services("GET", `catalogos/tiposRedes`);
      dataAPI5 = await services("GET", `oficinas/informacion/${idOfi}`);
      // dataAPI6= await services("GET", `catalogos/tiposAsentamientos/${dataAPI5.data[0].ubicacion.codigo_postal}`);
      let array_telefonos = [{ numero: "", privacidad: null }];
      let array_emails = [{ email: "" }];
      let array_paginas = [{ url: "", privacidad: null, red: null }];
      let params = {};
      // params = dataAPI5.data[0].telefonos === null || !dataAPI5.data[0].telefonos || dataAPI5.data[0].telefonos == null ? { ...params, telefonos: array_telefonos } : { ...params }
      // params = dataAPI5.data[0].emails === null ? { ...params, emails: array_emails } : { ...params }
      // params = dataAPI5.data[0].paginas === null ? { ...params, paginas: array_paginas } : { ...params }

      if (dataAPI5.data[0].cp) {
        getAddress1(
          dataAPI5.data[0].cp,
          dataAPI,
          dataAPI2,
          dataAPI3,
          dataAPI4,
          params
        );
      } else {
        setState({
          ...state,
          catOficinas: dataAPI.data,
          catPrivacidad: dataAPI2.data,
          catOfiAnt: dataAPI3.data,
          catRedes: dataAPI4.data,
          ...params,
        });
      }

      let horarioVerificacion = {
        inicio: dayjs(Date.parse("01 Jan 2024 " + "00" + ":00")),
        fin: dayjs(Date.parse("01 Jan 2024 " + "00" + ":00")),
      };
      if (dataAPI5.data[0].horario) {
        horarioVerificacion = {
          inicio: dayjs(
            Date.parse("01 Jan 2024 " + dataAPI5.data[0].horario.inicio + ":00")
          ),
          fin: dayjs(
            Date.parse("01 Jan 2024 " + dataAPI5.data[0].horario.fin + ":00")
          ),
        };
      }
      setInput({ ...dataAPI5.data[0], horario: horarioVerificacion });
      setIsLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const [addInput, setInput] = useState({
    id: "",
    nombre: "",
    siglas: "",
    ur: null,
    cat_oficina_id: "",
    dependencia: idDependencia,
    oficina_ant: "",
    asentamiento: "",
    calle: "",
    num_ext: "",
    num_int: "",
    longitud: "",
    latitud: "",
    paginas: [
      {
        id: null,
        url: "",
        red: "",
        privacidad: "",
      },
    ],
    emails: [
      {
        id: null,
        email: "",
      },
    ],
    horario: { inicio: null, fin: null },
    telefonos: [
      {
        id: "",
        numero: "",
        privacidad: "",
        extensiones: [
          {
            id: "",
            extension: "",
            privacidad: "",
          },
        ],
        marcaciones_cortas: [
          {
            id: "",
            mc: "",
          },
        ],
      },
    ],
  });

  const actualizarOficinas = async () => {
    setInfoOfi({ ...infoOfi, loading: true });
    let dataAPI;
    if (idDependencia) {
      dataAPI = await services(
        "GET",
        `funcionarios/listarOficinas/${idDependencia}`
      );
    } else {
      dataAPI = await services(
        "GET",
        `funcionarios/listarOficinas/${idDependencia}`
      );
    }

    if (dataAPI.status === "success") {
      setInfoOfi({ ...infoOfi, info: dataAPI.data, loading: false });
    }
    if (dataAPI.status === "info") {
      setInfoOfi({ ...infoOfi, loading: false });
    }
  };

  const handleOffice = (event) => {
    setInput({ ...addInput, cat_oficina_id: event.target.value });
  };

  const handleOfficeAnt = (event) => {
    setInput({ ...addInput, oficina_ant: event.target.value });
  };

  const handleAsentamiento = (event) => {
    setInput({ ...addInput, asentamiento_id: event.target.value });
  };

  const handlePrivacyTelefono = (event, i) => {
    let array = addInput.telefonos;
    array[i].privacidad = event;
    setInput({ ...addInput, telefonos: array });
  };

  const handlePrivacyExtencion = (event, i, j) => {
    let array = addInput.telefonos;
    array[i].extensiones[j].privacidad = event;
    setInput({ ...addInput, telefonos: array });
  };
  const handlePrivacyPagina = (event, i) => {
    addInput.paginas[i].privacidad = event.target.value;
    setInput({ ...addInput });
  };

  const handleRedes = (event, i) => {
    addInput.paginas[i].red = event.target.value;
    setInput({ ...addInput });
  };

  const getAddress = async (CP) => {
    if (CP.toString().length == 5) {
      const dataGetAddress = await services(
        "GET",
        `catalogos/tiposAsentamientos/${CP}`
      );
      setState({
        ...state,
        catAsentamiento: dataGetAddress.data[0].asentamientos,
        municipio: dataGetAddress.data[0].municipio,
        cp: CP,
      });
      setInput({ ...addInput, cp: CP });
    } else {
      setState({ ...state, cp: CP });
      setInput({ ...addInput, cp: CP });
    }
  };

  const getAddress1 = async (
    CP,
    dataAPI,
    dataAPI2,
    dataAPI3,
    dataAPI4,
    params
  ) => {
    if (CP.toString().length == 5) {
      const dataGetAddress = await services(
        "GET",
        `catalogos/tiposAsentamientos/${CP}`
      );
      setState({
        ...state,
        catAsentamiento: dataGetAddress.data[0].asentamientos,
        municipio: dataGetAddress.data[0].municipio,
        cp: CP,
        catOficinas: dataAPI.data,
        catPrivacidad: dataAPI2.data,
        catOfiAnt: dataAPI3.data,
        catRedes: dataAPI4.data,
        ...params,
      });
    } else {
      setState({ ...state, cp: CP });
    }
  };

  const handleHorarioInicio = (time) => {
    setInput({ ...addInput, horario: { ...addInput.horario, inicio: time } });
  };
  const handleHorarioFin = (time) => {
    // const {$H, $m} = time;
    setInput({ ...addInput, horario: { ...addInput.horario, fin: time } });
  };

  /* manejo de errores: principio */
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let newErrors = {};
    if (!addInput.nombre) {
      newErrors.nombre = "El campo nombre es requerido";
    }
    // if (!addInput.cat_oficina) { newErrors.cat_oficina = "El campo categoria de oficina es requerido" }
    if (addInput.emails) {
      const uniqueEmailsSet = new Set(); // Conjunto para almacenar correos electrónicos únicos
      const filteredData = []; // Array para almacenar los objetos únicos
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      addInput.emails.forEach((item) => {
        const email = item.email;
        if (email && !uniqueEmailsSet.has(email)) {
          uniqueEmailsSet.add(email);
          filteredData.push(item);
        }
      });

      if (filteredData.length !== addInput.emails.length) {
        newErrors.emails = "No se pueden repetir correos favor de revisar";
      }

      addInput.emails.map((email) => {
        if (email === "") {
          newErrors.emails = "Al menos un Email debe ser ingresado";
        } else {
          let valido = email.email.match(regexEmail);
          if (!valido) {
            newErrors.emails = "Ingrese un email valido";
          }
        }
      });
    }

    if (addInput.telefonos) {
      addInput.telefonos.forEach((telefono) => {
        if (telefono.numero === "") {
          newErrors.telefonos = "Al menos un telefono debe ser ingresado";
        } else if (telefono.numero.length < 10) {
          newErrors.telefonos = "El telefono debe ser de 10 digitos";
        }
      });
    }
    if (addInput.paginas) {
      addInput.paginas.forEach((pagina) => {
        if (pagina.url === "") {
          newErrors.paginas = "Al menos una pagina debe ser ingresada";
        }
      });
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  /* manejo de errores: fin */

  /* Manejo de POST: inicio */

  const handleSubmit = async () => {
    if (validateForm()) {
      // postRequest();
      const newHorarioFin = new Date(addInput.horario.fin);
      const newHorarioInicio = new Date(addInput.horario.inicio);
      const horFin =
        ("0" + newHorarioFin.getHours()).slice(-2) +
        ":" +
        ("0" + newHorarioFin.getMinutes()).slice(-2);
      const horIni =
        ("0" + newHorarioInicio.getHours()).slice(-2) +
        ":" +
        ("0" + newHorarioInicio.getMinutes()).slice(-2);
      const newHorario = {
        inicio: horIni.toString(),
        fin: horFin.toString(),
      };
      setIsLoading(true);
      let response;
      response = await services("POST", "oficinas/modificaOficina", {
        ...addInput,
        horario: newHorario,
      });

      if (response.status === "success") {
        Swal.fire({
          title: "Proceso completado",
          text: response.message,
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#6A0F49",
          confirmButtonText: "ACEPTAR",
        }).then((result) => {
          if (result.isConfirmed) {
            hiddenPopUp();
            actualizarOficinas();
          }
        });
        setIsLoading(false);
      } else if (response.status === 422) {
        const concatenateValues = (response) => {
          if (response && response.data) {
            const values = Object.values(response.data);
            return Array.from(new Set(values.flat())).join("\n");
          }
          return "";
        };

        const concatenatedValues = concatenateValues(response);
        // "El funcionario ya se encuentra registrado."
        Swal.fire({
          title: "Atención",
          text: concatenatedValues,
          icon: "warning",
          confirmButtonColor: "#6A0F49",
          confirmButtonText: "ACEPTAR",
        });
        setIsLoading(false);
      } else if (response.status === "error") {
        Swal.fire({
          title: "Lo sentimos",
          text: response.message,
          icon: "warning",
          confirmButtonColor: "#6A0F49",
          confirmButtonText: "ACEPTAR",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            hiddenPopUp();
          }
        });
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const postRequest = async () => {
    let response;
    if (!addInput.horario.inicio && !addInput.horario.fin) {
      response = await services("POST", "oficinas/nuevaOficina", {
        ...addInput,
        horario: null,
      });
    } else {
      response = await services("POST", "oficinas/nuevaOficina", addInput);
    }

    if (response.status === "success") {
      Swal.fire({
        title: "Proceso completado",
        text: response.message,
        icon: "success",
        confirmButtonColor: "#6A0F49",
        confirmButtonText: "ACEPTAR",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          hiddenPopUp();
        }
      });
    } else if (response.status === 422) {
      const concatenateValues = (response) => {
        if (response && response.data) {
          const values = Object.values(response.data);
          return Array.from(new Set(values.flat())).join("\n");
        }
        return "";
      };

      const concatenatedValues = concatenateValues(response);
      // "El funcionario ya se encuentra registrado."
      Swal.fire({
        title: "Atención",
        text: concatenatedValues,
        icon: "warning",
        confirmButtonColor: "#6A0F49",
        confirmButtonText: "ACEPTAR",
      });
    }
  };
  const obtenerMensajes = (obj) => {
    let mensajes = "";

    Object.values(obj).forEach((value) => {
      if (typeof value === "object" && value !== null) {
        // Verificar si el valor es un objeto no nulo
        if ("message" in value) {
          mensajes += obtenerMensajes(value.message);
        } else {
          mensajes += obtenerMensajes(value);
        }
      } else if (Array.isArray(value)) {
        // Concatenar los elementos de un array
        mensajes += value.join(" ");
      } else {
        mensajes += value;
      }
    });

    return mensajes;
  };

  /* Manejo de POST: fin */

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInput({ ...addInput, [name]: value });
  };

  return (
    <>
      <OverLayIU onClick={hiddenPopUp} />
      {isLoading ? (
        <GifLoader /> // Componente de carga
      ) : (
        <div className="wizard">
          <div className="d-flex justify-content-end ">
            <div className="cursor-pointer" onClick={hiddenPopUp}>
              <IconUI>
                <GrClose />
              </IconUI>
            </div>
          </div>

          <h3 className="text-bold">Editar Oficina</h3>
          <div
            className="d-flex-inline justify-content-center "
            style={{ width: "90%", margin: "auto" }}
          >
            <div className="d-flex">
              <div className="d-flex flex-lg-column w-100">
                <CssTextField
                  required
                  id="oficina"
                  name="oficina"
                  value={
                    addInput.nombre != undefined
                      ? addInput.nombre
                      : addInput.nombre
                  }
                  label="Nombre de la Oficina"
                  size="small"
                  margin="dense"
                  error={!!errors.nombre}
                  helperText={errors.nombre || ""}
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={(evt) => {
                    setInput({ ...addInput, nombre: evt.target.value });
                  }}
                />
                <div className="d-flex justify-content-between">
                  <CssTextField
                    className="col-md me-1"
                    margin="dense"
                    id="siglas"
                    name="siglas"
                    value={addInput.siglas}
                    label="Siglas"
                    size="small"
                    onChange={(evt) => {
                      setInput({ ...addInput, siglas: evt.target.value });
                    }}
                  />
                  <CssTextField
                    className="col-md me-1"
                    margin="dense"
                    id="oficina_ant"
                    name="oficina_ant"
                    value={addInput.oficina_ant}
                    select
                    label="Oficina del Jefe Superior"
                    SelectProps={{
                      native: false,
                    }}
                    size="small"
                    onChange={handleOfficeAnt}
                    style={{ width: "100% !important" }}
                    disabled
                  >
                    {state.catOfiAnt &&
                      state.catOfiAnt.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.oficina}
                        </MenuItem>
                      ))}
                  </CssTextField>

                  <CssTextField
                    required
                    className="col-md"
                    margin="dense"
                    id="cat_oficina"
                    name="cat_oficina"
                    value={
                      addInput.cat_oficina_id !== undefined
                        ? addInput.cat_oficina_id
                        : addInput.cat_oficina_id
                    }
                    error={!!errors.cat_oficina}
                    helperText={errors.cat_oficina || ""}
                    select
                    label="Tipo de Oficina"
                    SelectProps={{
                      native: false,
                    }}
                    size="small"
                    onChange={handleOffice}
                    style={{ width: "100% !important" }}
                  >
                    {state.catOficinas.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.tipo}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <CssTextField
                className="col-md me-1"
                id="cp"
                name="cp"
                label="Código Postal"
                size="small"
                margin="dense"
                onKeyPress={onlyCP}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  maxLength: 5,
                }}
                value={addInput.cp ?? ""}
                onChange={(evt) => {
                  getAddress(evt.target.value);
                }}
              />

              <CssTextField
                className="col-md me-1"
                margin="dense"
                id="asentamiento"
                name="asentamiento"
                value={addInput.asentamiento_id ?? ""}
                select
                label="Colonias"
                SelectProps={{
                  native: false,
                }}
                size="small"
                onChange={handleAsentamiento}
                style={{ width: "100% !important" }}
              >
                {state.catAsentamiento.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.asentamiento}
                  </MenuItem>
                ))}
              </CssTextField>

              <CssTextField
                className="col-md"
                id="municipio"
                name="municipio"
                label="Municipio"
                size="small"
                margin="dense"
                value={state.municipio}
              />
            </div>

            <div className="d-flex flex-column w-100">
              <CssTextField
                id="calle"
                name="calle"
                value={
                  addInput.calle != undefined ? addInput.calle : addInput.calle
                }
                label="Calle"
                size="small"
                margin="dense"
                onChange={(evt) => {
                  setInput({ ...addInput, calle: evt.target.value });
                }}
              />
            </div>

            <div className="d-flex justify-content-between">
              <CssTextField
                className="col-md me-1"
                id="num_ext"
                name="num_ext"
                value={
                  addInput.num_ext != undefined
                    ? addInput.num_ext
                    : addInput.num_ext
                }
                label="Número Exterior"
                size="small"
                margin="dense"
                onChange={(evt) => {
                  setInput({ ...addInput, num_ext: evt.target.value });
                }}
                onKeyPress={onlyNumber}
              />

              <CssTextField
                className="col-md "
                id="num_int"
                name="num_int"
                value={
                  addInput.num_int != undefined
                    ? addInput.num_int
                    : addInput.num_int
                }
                label="Número Interior"
                size="small"
                margin="dense"
                onChange={(evt) => {
                  setInput({ ...addInput, num_int: evt.target.value });
                }}
                onKeyPress={onlyNumber}
              />
            </div>

            <h5 className="text-bold">Horario</h5>

            <div className="d-flex justify-content-center">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                  className="me-1"
                  label="Inicio"
                  // value={dayjs(Date.parse('01 Jan 2024 ' + addInput.horario.inicio +':00'))}
                  value={addInput.horario.inicio}
                  onChange={(newValue) => {
                    handleHorarioInicio(newValue);
                  }}
                  renderInput={(params) => <CssTextField {...params} />}
                />
                <MobileTimePicker
                  label="Fin"
                  value={addInput.horario.fin}
                  onChange={(newValue) => {
                    handleHorarioFin(newValue);
                  }}
                  renderInput={(params) => <CssTextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>

          {/* Boton de agregar telefonos */}

          <div className=" d-flex mt-1">
            <button
              className=" pill pill-little"
              onClick={() => {
                if (addInput.telefonos) {
                  addInput.telefonos.push({
                    id: null,
                    numero: "",
                    privacidad: "",
                    extensiones: [
                      {
                        id: null,
                        extension: "",
                        privacidad: "",
                      },
                    ],
                    marcaciones_cortas: [
                      {
                        id: null,
                        mc: "",
                      },
                    ],
                  });
                } else {
                  addInput.telefonos = [
                    {
                      id: null,
                      numero: "",
                      privacidad: "",
                      extensiones: [
                        {
                          id: null,
                          extension: "",
                          privacidad: "",
                        },
                      ],
                      marcaciones_cortas: [
                        {
                          id: null,
                          mc: "",
                        },
                      ],
                    },
                  ];
                }
                setInput({ ...addInput });
              }}
            >
              <IconUI size={20}>
                <BiPlus />
              </IconUI>
              Agregar Telefono
            </button>
          </div>

          {/* Arreglo de Telefonos, extenciones y marcaciones cortas */}
          <div
            className="d-flex flex-column "
            style={{ width: "90%", margin: "auto" }}
          >
            <div className="d-flex flex-column align-self-start">
              {addInput.telefonos &&
                addInput.telefonos.map((telefono, index) => {
                  return (
                    <Fragment key={index}>
                      <div className="d-flex">
                        <div
                          className="d-flex text-guinda me-2"
                          style={{ width: "150px" }}
                        >
                          <CssTextField
                            className="col-md"
                            margin="dense"
                            id="outlined-select-currency-native"
                            select
                            label="Privacidad"
                            SelectProps={{
                              native: false,
                            }}
                            size="small"
                            value={
                              addInput.telefonos[index].privacidad !== undefined
                                ? addInput.telefonos[index].privacidad
                                : addInput.telefonos[index].privacidad
                            }
                            onChange={(evt) => {
                              handlePrivacyTelefono(evt.target.value, index);
                            }}
                            style={{ width: "100% !important" }}
                          >
                            {state.catPrivacidad &&
                              state.catPrivacidad.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.tipo}
                                </MenuItem>
                              ))}
                          </CssTextField>
                        </div>

                        <div
                          className="d-flex flex-column me-2"
                          style={{ width: "300px" }}
                        >
                          <CssTextField
                            id={`telefono_${index}`}
                            name={`telefono_${index}`}
                            value={addInput.telefonos[index].numero}
                            error={!!errors.telefono}
                            helperText={errors.telefono || ""}
                            label="Teléfono (10 Dígitos)"
                            size="small"
                            margin="dense"
                            onChange={(evt) => {
                              addInput.telefonos[index].numero =
                                evt.currentTarget.value;
                              setInput({ ...addInput });
                            }}
                            inputProps={{
                              maxLength: 10,
                            }}
                            onKeyPress={onlyNumberPhone}
                          />
                        </div>

                        <div className=" d-flex m-1 ">
                          <button
                            className=" pill pill-little"
                            onClick={() => {
                              addInput.telefonos[index].extensiones.push({
                                id: null,
                                extension: "",
                                privacidad: "",
                              });
                              setInput({ ...addInput });
                            }}
                          >
                            <IconUI size={20}>
                              <BiPlus />
                            </IconUI>
                            Extensión
                          </button>
                        </div>

                        <div
                          className=" d-flex m-1"
                          onClick={() => {
                            addInput.telefonos[index].marcaciones_cortas.push({
                              id: null,
                              mc: "",
                            });
                            setInput({ ...addInput });
                          }}
                        >
                          <button className=" pill pill-little">
                            <IconUI size={20}>
                              <BiPlus />
                            </IconUI>
                            M.C
                          </button>
                        </div>

                        {/* Quitar todo el arreglo de numeros */}
                        <div
                          className=" d-flex m-1 ms-3"
                          onClick={() => {
                            addInput.telefonos.splice(index, 1);
                            setInput({ ...addInput });
                          }}
                        >
                          <IconUI size={"30px"} color={"#6A0F49"}>
                            <AiOutlineMinusCircle />
                          </IconUI>
                        </div>
                      </div>

                      {/* Inputs Extenciones */}
                      <div className="d-flex flex-column ">
                        {addInput.telefonos[index].extensiones.map(
                          (extension, j) => {
                            return (
                              <div className="d-flex" key={j}>
                                <div
                                  className="d-flex text-guinda me-2"
                                  style={{ width: "150px" }}
                                >
                                  <CssTextField
                                    className="col-md"
                                    margin="dense"
                                    id="outlined-select-currency-native"
                                    select
                                    label="Privacidad"
                                    SelectProps={{
                                      native: false,
                                    }}
                                    size="small"
                                    value={
                                      addInput.telefonos[index].extensiones[j]
                                        .privacidad !== undefined
                                        ? addInput.telefonos[index].extensiones[
                                            j
                                          ].privacidad
                                        : ""
                                    }
                                    onChange={(evt) => {
                                      handlePrivacyExtencion(
                                        evt.target.value,
                                        index,
                                        j
                                      );
                                    }}
                                    style={{ width: "100% !important" }}
                                  >
                                    {state.catPrivacidad.map((option) => (
                                      <MenuItem
                                        key={option.id}
                                        value={option.id}
                                      >
                                        {option.tipo}
                                      </MenuItem>
                                    ))}
                                  </CssTextField>
                                </div>

                                <div
                                  className="d-flex flex-column me-2"
                                  style={{ width: "300px" }}
                                >
                                  <CssTextField
                                    id="outlined-select-currency-native"
                                    label="Extensión"
                                    size="small"
                                    margin="dense"
                                    value={
                                      addInput.telefonos[index].extensiones[j]
                                        .extension
                                    }
                                    onChange={(evt) => {
                                      addInput.telefonos[index].extensiones[
                                        j
                                      ].extension = evt.currentTarget.value;
                                      setInput({ ...addInput });
                                    }}
                                    inputProps={{
                                      maxLength: 5,
                                    }}
                                    onKeyPress={onlyNumber}
                                  />
                                </div>

                                <div
                                  className=" d-flex mt-1"
                                  onClick={() => {
                                    addInput.telefonos[
                                      index
                                    ].extensiones.splice(j, 1);
                                    setInput({ ...addInput });
                                  }}
                                >
                                  <IconUI size={"30px"} color={"#6A0F49"}>
                                    <AiOutlineMinusCircle />
                                  </IconUI>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>

                      {/* Inputs Marcaciones Cortas */}

                      <div className="d-flex flex-column  ">
                        {addInput.telefonos[index].marcaciones_cortas.map(
                          (marcacion, k) => {
                            return (
                              <div className="d-flex " key={k}>
                                <div
                                  className="d-flex flex-column me-2"
                                  style={{ width: "300px" }}
                                >
                                  <CssTextField
                                    id="outlined-select-currency-native"
                                    label="Marcación Corta"
                                    size="small"
                                    margin="dense"
                                    value={
                                      addInput.telefonos[index]
                                        .marcaciones_cortas[k].mc
                                    }
                                    onChange={(evt) => {
                                      addInput.telefonos[
                                        index
                                      ].marcaciones_cortas[k].mc =
                                        evt.currentTarget.value;
                                      setInput({ ...addInput });
                                    }}
                                    inputProps={{
                                      maxLength: 5,
                                    }}
                                    onKeyPress={onlyNumber}
                                  />
                                </div>

                                <div
                                  className=" d-flex mt-1"
                                  onClick={() => {
                                    addInput.telefonos[
                                      index
                                    ].marcaciones_cortas.splice(k, 1);
                                    setInput({ ...addInput });
                                  }}
                                >
                                  <IconUI size={"30px"} color={"#6A0F49"}>
                                    <AiOutlineMinusCircle />
                                  </IconUI>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </Fragment>
                  );
                })}
            </div>
          </div>

          {/* Boton de Emails */}

          <div className=" d-flex mt-1">
            <button
              className=" pill pill-little"
              onClick={() => {
                if (addInput.emails) {
                  addInput.emails.push({
                    id: null,
                    email: "",
                  });
                } else {
                  addInput.emails = [
                    {
                      id: null,
                      email: "",
                    },
                  ];
                }
                setInput({ ...addInput });
              }}
            >
              <IconUI size={20}>
                <BiPlus />
              </IconUI>
              Agregar Email
            </button>
          </div>

          {/* Arreglo de emails */}

          <div
            className="d-flex flex-column "
            style={{ width: "90%", margin: "auto" }}
          >
            {addInput.emails.map((item, index) => {
              return (
                <div className="d-flex align-self-start" key={index}>
                  <div
                    className="d-flex flex-column  me-2"
                    style={{ width: "470px" }}
                  >
                    <CssTextField
                      label="Correo"
                      id="correo"
                      name="correo"
                      value={addInput.emails[index].email}
                      error={!!errors.emails}
                      helperText={errors.emails || ""}
                      size="small"
                      margin="dense"
                      onChange={(evt) => {
                        addInput.emails[index].email = evt.currentTarget.value;
                        setInput({ ...addInput });
                      }}
                      onKeyPress={(e) => maxLengthLimitInput(e, 100, "email")}
                    />
                  </div>

                  <div
                    className=" d-flex mt-1"
                    onClick={() => {
                      addInput.emails.splice(index, 1);
                      setInput({ ...addInput });
                    }}
                  >
                    <IconUI size={"30px"} color={"#6A0F49"}>
                      <AiOutlineMinusCircle />
                    </IconUI>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Boton de paginas */}

          <div className=" d-flex mt-1">
            <button
              className=" pill pill-little"
              onClick={() => {
                if (addInput.paginas) {
                  addInput.paginas.push({
                    id: null,
                    url: "",
                    red: null,
                    privacidad: null,
                  });
                } else {
                  addInput.paginas = [
                    {
                      id: null,
                      url: "",
                      red: null,
                      privacidad: null,
                    },
                  ];
                }
                setInput({ ...addInput });
              }}
            >
              <IconUI size={20}>
                <BiPlus />
              </IconUI>
              Agregar Pagina
            </button>
          </div>

          {/* Arreglo de paginas */}

          <div
            className="d-flex flex-column "
            style={{ width: "90%", margin: "auto" }}
          >
            {addInput.paginas &&
              addInput.paginas.map((item, i) => {
                return (
                  <div className="d-flex align-self-start" key={i}>
                    <div
                      className="d-flex flex-column  me-2"
                      style={{ width: "30%" }}
                    >
                      <CssTextField
                        className="col-md"
                        margin="dense"
                        id="outlined-select-currency-native"
                        select
                        value={addInput.paginas[i].privacidad}
                        label="Privacidad"
                        SelectProps={{
                          native: false,
                        }}
                        size="small"
                        style={{ width: "100% !important" }}
                        onChange={(e) => handlePrivacyPagina(e, i)}
                      >
                        {state.catPrivacidad.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.tipo}
                          </MenuItem>
                        ))}
                      </CssTextField>
                    </div>

                    <div
                      className="d-flex flex-column  me-2"
                      style={{ width: "30%" }}
                    >
                      <CssTextField
                        className="col-md"
                        margin="dense"
                        id="outlined-select-currency-native"
                        select
                        label="Redes"
                        SelectProps={{
                          native: false,
                        }}
                        size="small"
                        value={
                          addInput.paginas[i].red != undefined
                            ? addInput.paginas[i].red
                            : addInput.paginas[i].red
                        }
                        onChange={(e) => handleRedes(e, i)}
                        style={{ width: "100% !important" }}
                      >
                        {state.catRedes.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.tipo}
                          </MenuItem>
                        ))}
                      </CssTextField>
                    </div>
                    <div
                      className="d-flex flex-column  me-2"
                      style={{ width: "470px" }}
                    >
                      <CssTextField
                        id={`pagina_${i}`}
                        name={`pagina_${i}`}
                        label="Pagina Web"
                        size="small"
                        margin="dense"
                        value={addInput.paginas[i].url}
                        error={!!errors.paginas}
                        helperText={errors.paginas || ""}
                        onChange={(evt) => {
                          addInput.paginas[i].url = evt.currentTarget.value;
                          setInput({ ...addInput });
                        }}
                      />
                    </div>

                    <div
                      className=" d-flex mt-1"
                      onClick={() => {
                        addInput.paginas.splice(i, 1);
                        setInput({ ...addInput });
                      }}
                    >
                      <IconUI size={"30px"} color={"#6A0F49"}>
                        <AiOutlineMinusCircle />
                      </IconUI>
                    </div>
                  </div>
                );
              })}
          </div>

          {/* Botones de Cancelar y Guardar */}
          <div className="d-flex justify-content-center">
            <button className="pill-red" onClick={hiddenPopUp}>
              <IconUI size={25}>
                <RiCloseFill />
              </IconUI>
              <h6 className="pt-2">Cancelar</h6>
            </button>

            <button className="pill-green " onClick={handleSubmit}>
              <IconUI size={25}>
                <RiSave3Fill />
              </IconUI>
              <h6 className="pt-2">Guardar</h6>
            </button>
          </div>
        </div>
      )}
    </>
  );
}
