import { useContext, useEffect, useState } from 'react';
import { services } from '../services/api';
import { useParams } from 'react-router-dom';
import IconUI from '../components/ui/icon_ui'
import { TiArrowBackOutline } from "react-icons/ti";
import { FiMail } from "react-icons/fi";
import Location from '../assets/img/card/Location.svg'
import Phone from '../assets/img/card/Phone.svg'
import PhoneMC from '../assets/img/card/PhoneMC.svg'
import WEB from '../assets/img/card/WEB.svg'

import Escudo from '../assets/img/Escudo.svg'
import GifLoader from "../components/ui/gifLoader"



import { GlobalContext } from '../context/global_context';
import { useHistory } from 'react-router-dom';


export function DependencyPrivateView() {

    const { idDependencia } = useParams()

    const { idDep, setIdDep } = useContext(GlobalContext)
    const history = useHistory()

    const [state, setState] = useState({
        info: [],
        loading: false
    })

    useEffect(async () => {
        let isMounted = true
        if (isMounted) {

            setState({ ...state, loading: true })
            let dataAPI;
            if (idDep) {
                dataAPI = await services("GET", `funcionarios/mostrarDependencia/${idDep}`)
            } else {
                dataAPI = await services("GET", `funcionarios/mostrarDependencia/${idDependencia}`)
            }            
            if (dataAPI.status === "success") {
                setState({ ...state, info: dataAPI.data.dependencia, loading: false })
            }
            
        }
        return () => {
            isMounted = false
        }
    }, [])

    return (
        <>
            {state.loading === true ?
                <GifLoader />
                :
                <div id="publicServer" className='publicServer'>
                    <div className="container text-center ">
                        <div className="d-block justify-content-center">
                            <div className="pt-3">
                                <h2 className="text-center text-large text-secondary">
                                    Bienvenido al Directorio Estatal de Servidores Públicos
                                </h2>
                            </div>
                            <div>
                                <div className="mb-4 row row-cols-1 row-cols-md-1 g-4 ">
                                    <div className="col ml-4">
                                        <div className="card card--rounded ">
                                            <div className="card-body ">
                                                <div className="d-flex pb-4 justify-content-center input-group text-center">
                                                    <div className='col-2-justify-content-center col-md-2 mb-2'>
                                                        <img src={Escudo} style={{ height: 150, width: 100 }} />
                                                    </div>
                                                    <div className='mt-5 text-center'>
                                                        <h2 className='text-bolder'>{state.info[0]?.nombre}</h2>
                                                        <h4 className="text-center text-bolder card-title">{state.info[0]?.siglas}</h4>
                                                    </div>
                                                    <div className='pt-2 col-10'>
                                                        <h5 className="text-center">{state.info[0]?.descripcion}</h5>
                                                    </div>
                                                </div>

                                                <div className="d-inline-flex pb-4 justify-content-right input-group text-center">
                                                    <div className='col-2 justify-content-right'>
                                                        <img src={Location} style={{ height: 25, width: 25 }} alt="location logo" />
                                                    </div>
                                                    <div className='col-8 text-start'>
                                                        <h6 className='text-bold'>{state.info[0]?.direccion? state.info[0]?.direccion : 'Sin dirección'}</h6>
                                                    </div>
                                                </div>

                                                <div className="d-inline-flex pb-4 justify-content-right input-group text-center">
                                                    <div className='col-2'>
                                                        <img src={Phone} style={{ height: 25, width: 25 }} alt="phone logo" />
                                                    </div>
                                                    <div className='col-4 text-start text-bold'>
                                                        {state.info[0]?.telefono ?
                                                            state.info[0]?.telefono[0].numero
                                                            :
                                                            'Sin numero'
                                                        }
                                                    </div>
                                                    <div className='col-2 text-center'>
                                                        <img src={PhoneMC} style={{ height: 25, width: 25 }} alt="phonemc logo" />
                                                    </div>
                                                    <div className='col-4 flex text-start'>
                                                        <h6 className='text-bold'> M.C. </h6>
                                                        {state.info[0]?.telefono ?
                                                            <div>
                                                                {state.info[0]?.telefono[0].marcacion_corta + ''}
                                                            </div>
                                                            :

                                                            <h6> Sin número</h6>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="d-flex pb-4  input-group ">
                                                    <div className='col-2 justify-content-right'>
                                                        <img src={WEB} style={{ height: 25, width: 25 }} alt="web logo" />
                                                    </div>
                                                    <div className='col-4 text-start'>
                                                    {state.info[0]?.url_web?
                                                        state.info[0].url_web.map((url)=>{
                                                            return <p><a className='text-bold'href={url}>{url}</a></p>                       
                                                        })
                                                        :
                                                        <h6> Sin Página Web</h6>
                                                        }
                                                    </div>
                                                    <div className='col-5 d-flex flex-row-reverse'>
                                                        <button className='pill ' onClick={() => {
                                                            setIdDep(state.info[0].id)
                                                            history.push(`/privadoOficinas/${state.info[0].id}`)
                                                        }}> Mostrar Oficinas</button>
                                                    </div>
                                                </div>

                                                <div>
                                                    <iframe width="90%" height="500" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={`https://maps.google.com/maps?width=100%25&height=600&hl=en&q=${state.info[0]?.longitud},${state.info[0]?.latitud}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}>
                                                        <a href="https://www.maps.ie/distance-area-calculator.html">distance maps</a>
                                                    </iframe>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }
        </>

    )
}