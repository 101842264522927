import React, { useState, useEffect,useContext } from 'react';
import { Link, Route, useHistory, useLocation  } from 'react-router-dom';
import IconUI from './icon_ui'
import { AiOutlineLeftSquare } from 'react-icons/ai'
import { GlobalContext } from '../../context/global_context';



const BackArrow = ({ to  }) => {

    const history = useHistory();

    const {  infoOfi, setInfoOfi} = useContext(GlobalContext)

    const [ listaDirecciones, setListaDirecciones ] = useState( [] )

    const linkStyle = {
        width: "9%",
        display: "flex",
        alignItems: "baseline",
        textDecoration: "none",
        color: "inherit",
        marginLeft: "5px",
        alignContent: "center",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "center"
      };
    
      const iconStyle = {
        marginRight: '4px',
      };


    const rutasExcluidas = ['/login', '/', '/administradorDependencias/', '/administradorDependencias']; // Rutas que deseamos excluir

    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState(location.pathname);
  
    useEffect(() => {
      setCurrentLocation(location.pathname);
      (location.pathname==="/") ? setListaDirecciones( [ location.pathname ] ) : setListaDirecciones( [...listaDirecciones, location.pathname] )
    }, [location.pathname]);

    const goBack = () => {
      setInfoOfi({ ...infoOfi, filtro: '',info: [], loading: false})
      history.goBack(); // Navegar hacia la ubicación anterior
    };
  

  return (
    <Route
      path={to}
      render={() => {
        const shouldRender = !rutasExcluidas.includes(currentLocation);

        if (!shouldRender) {
          return null;
        }

        return (
            <Link to="#" onClick={goBack} style={linkStyle} >
                <IconUI size={'40px'} border>
                    <AiOutlineLeftSquare className='text-morado' style={iconStyle} />
                </IconUI>
                <h5 className='text-morado text-bolder'>Atrás</h5>
            </Link>
        );
      }}
    />
  );
};

export default BackArrow;
