import React, { useContext, useEffect, useState, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";

import IconUI from "../ui/icon_ui";
import { GrClose } from "react-icons/gr";
import { RiSave3Fill, RiCloseFill } from "react-icons/ri";
import { BiPlus } from "react-icons/bi";
import Swal from "sweetalert2";
import GifLoader from "./gifLoader";

import { BiUserCircle } from "react-icons/bi";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";

import OverLayIU from "./overlay_ui";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { GlobalContext } from "../../context/global_context";
import MenuItem from "@mui/material/MenuItem";
import { services } from "../../services/api";
import { onlyNumberPhone, maxLengthLimitInput } from "../../utils/inputsRules";

import { DisabledByDefault } from "@mui/icons-material";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#6A0F49",
    // color: '#8d949e',
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#6A0F49",
    // borderBottomColor: '#8d949e',
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#6A0F49",
      // borderColor: '#8d949e',
    },
    "&:hover fieldset": {
      borderColor: "#6A0F49",
      // borderColor: '#8d949e',
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6A0F49",
      // borderColor: '#8d949e',
    },
  },
});

export default function PopUpServer({ onclose, idFuncionario }) {
  const { idOficina } = useParams();

  const { statePublicServerAdmin, setStatePublicServerAdmin } =
    useContext(GlobalContext);

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const hiddenPopUp = () => {
    onclose();
  };

  const [state, setState] = useState({
    catPrivacidad: [],
    catRedes: [],
    catTitulos: [],
    catCargos: [],
  });

  const [servidor, setServidor] = useState({
    id: null,
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    curp: "",
    rfc: "",
    contrasenia: "1234567",
    cargo: null,
    titulo: null,
    oficina: idOficina,
    paginas: [],
    emails: [
      {
        id_email: null,
        email: null,
      },
    ],
    telefonos: [
      {
        numero: "",
        privacidad: 1,
      },
    ],
    imagen: {
      nombre_imagen: "funcionario1",
      ruta: "imagenes/ruta/funcionarios",
      privacidad_imagen: 1,
    },
  });

  const handleReiniciarServidor = () => {
    setServidor((prevObjeto) => {
      const newObjeto = JSON.parse(JSON.stringify(prevObjeto)); // Copia profunda del objeto actual
      //   Object.assign(newObjeto, servidorNuevo); // Reemplazo de valores con el segundo objeto
      return newObjeto;
    });
  };

  const [valueNuevoTitulo, setValueNuevoTitulo] = React.useState(null);
  const filter = createFilterOptions();

  useEffect(async () => {
    // setServidor({...servidor, ...servidorNuevo})
    // handleReiniciarServidor()
    let isMounted = true;
    setIsLoading(true);
    if (isMounted) {
      setState({ ...state, loading: true });
      let dataAPI,
        dataAPITiposRedes,
        dataAPITitulos,
        dataAPITiposCargos,
        dataAPIFuncionario;
      dataAPI = await services("GET", `catalogos/tiposPrivacidades`);
      dataAPITiposRedes = await services("GET", `catalogos/tiposRedes`);
      dataAPITitulos = await services("GET", `catalogos/tiposTitulos`);
      dataAPITiposCargos = await services("GET", `catalogos/tiposCargos`);
      setState({
        ...state,
        catPrivacidad: dataAPI.data,
        catRedes: dataAPITiposRedes.data,
        catTitulos: dataAPITitulos.data,
        catCargos: dataAPITiposCargos.data,
      });
      // let newArrayEmails = [];
      if (idFuncionario != null) {
        dataAPIFuncionario = await services(
          "GET",
          `administradorFuncionarios/informacion/${idOficina}`
        );
        // setServidor( {  })
        if (dataAPIFuncionario.status === "success") {
          // setServidor( {...dataAPIFuncionario.data[0], emails:[], titulo: { id:5, titulo:'Titulo prueba', abreviatura:'Lic.' }} )
          // if(dataAPIFuncionario.data[0].emails){
          //     dataAPIFuncionario.data[0].emails.map( (email)=>{
          //         newArrayEmails.push( email.email )
          //     } )
          //     // setServidor( { ...dataAPIFuncionario.data[0], emails: [...newArrayEmails] } )
          // }
          setIsLoading(false);
        }
        handleServidorExistente(
          dataAPIFuncionario.data[0].titulo,
          dataAPIFuncionario.data[0].cargo,
          dataAPITitulos.data,
          dataAPITiposCargos.data,
          dataAPIFuncionario.data[0]
        );
      }
      setIsLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const handlePrivacyTelefono = (event, i) => {
    servidor.telefonos[i].privacidad = event.target.value;
    setServidor({ ...servidor });
  };

  const handleTitulo = (event, newValue) => {
    setServidor({ ...servidor, titulo: newValue });
  };

  const handleServidorExistente = (
    idTitulo,
    idCargo,
    cat_titulos,
    cat_cargos,
    servidorConsultadoAPI
  ) => {
    // // Esperar a que se resuelva la promesa y obtener el objeto 'state.catTitulos'
    // const catTitulosExistentes = await services("GET", `catalogos/tiposTitulos`);
    // setState( {...state, catTitulos: catTitulosExistentes.data} )

    const encontrarObjetoPorId = (array, id, atributo) => {
      const foundObject = array.find((obj) => obj.id === id);
      return foundObject ? foundObject : null;
    };
    const objetoEncontradoTitulo = encontrarObjetoPorId(cat_titulos, idTitulo);
    // const objetoEncontradoCargo = encontrarObjetoPorId(cat_cargos, idCargo );
    let array_telefonos = [{ numero: "", privacidad: null }];
    let array_emails = [{ email: "" }];
    let array_paginas = [];
    let params = {};
    params =
      servidorConsultadoAPI.telefonos === null ||
      !servidorConsultadoAPI.telefonos ||
      servidorConsultadoAPI.telefonos == null
        ? { ...params, telefonos: array_telefonos }
        : { ...params };
    params =
      servidorConsultadoAPI.emails === null
        ? { ...params, emails: array_emails }
        : { ...params };
    params =
      servidorConsultadoAPI.paginas === null
        ? { ...params, paginas: array_paginas }
        : { ...params };
    setServidor({ ...servidorConsultadoAPI, cargo: idCargo, ...params });
    // setServidor( {...servidorConsultadoAPI, titulo: objetoEncontradoTitulo, cargo: idCargo , ...params} )
  };

  const handlePrivacyPagina = (event, i) => {
    servidor.paginas[i].privacidad = event.target.value;
    setServidor({ ...servidor });
  };

  const handleRedes = (event, i) => {
    servidor.paginas[i].red = event.target.value;
    setServidor({ ...servidor });
  };

  const handleEmails = (event, i) => {
    servidor.emails[i].email = event.target.value;
    setServidor({ ...servidor });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setServidor({ ...servidor, [name]: value });
  };

  /* manejo de submit titulo: principio */
  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    setDialogValue({
      title: "",
      year: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    titulo: "",
  });

  const handleSubmitTitulo = (event) => {
    event.preventDefault();
    setValueNuevoTitulo({
      titulo: dialogValue.titulo,
    });
    handleClose();
  };
  /* manejo de submit titulo: final */

  /* manejo de errores: principio */
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let newErrors = {};
    const re =
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    const re2 =
      /^([A-ZÑ][AEIOUXÁÉÍÓÚ][A-ZÑ]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

    if (!servidor.nombre) {
      newErrors.nombre = "El campo nombre es requerido";
    }
    if (!servidor.apellido_paterno) {
      newErrors.apellido_paterno = "El campo Apellido Paterno es requerido";
    }
    // if(!servidor.apellido_materno){ newErrors.apellido_materno = "El campo Apellido Materno es requerido" }
    if (!servidor.titulo) {
      newErrors.titulo = "El campo Titulo es requerido";
    }
    if (!servidor.cargo) {
      newErrors.cargo = "El campo Cargo es requerido";
    }

    if (servidor.curp && servidor.curp.length !== 18) {
      newErrors.curp = "El campo CURP debe tener un mínimo de 18 caracteres";
    } else if (servidor.curp) {
      let valido = servidor.curp.match(re2);
      if (!valido) {
        newErrors.curp = "El campo CURP no es valido favor de revisar";
      }
    }

    if (servidor.rfc && servidor.rfc.length !== 13) {
      newErrors.rfc = "El campo RFC debe tener un mínimo de 13 caracteres";
    } else if (servidor.rfc) {
      let valido = servidor.rfc.match(re);
      if (!valido) {
        newErrors.rfc = "El campo RFC no es valido favor de revisar";
      }
    }

    if (servidor.emails) {
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const uniqueEmailsSet = new Set(); // Conjunto para almacenar correos electrónicos únicos
      const filteredData = []; // Array para almacenar los objetos únicos

      servidor.emails.forEach((item) => {
        const email = item.email;
        if (email && !uniqueEmailsSet.has(email)) {
          uniqueEmailsSet.add(email);
          filteredData.push(item);
        }
      });

      if (filteredData.length !== servidor.emails.length) {
        newErrors.emails = "No se pueden repetir correos favor de revisar";
      }

      servidor.emails.map((email) => {
        if (!email.email || email.email === "") {
          newErrors.emails = "Al menos un Email debe ser ingresado";
        } else {
          let valido = email.email.match(regexEmail);
          if (!valido) {
            newErrors.emails = "Ingrese un email valido";
          }
        }
      });
    }

    if (servidor.telefonos) {
      servidor.telefonos.forEach((telefono) => {
        if (telefono.numero === "") {
          newErrors.telefonos = "Al menos un telefono debe ser ingresado";
        } else if (telefono.numero.length < 10) {
          newErrors.telefonos = "El telefono debe ser de 10 digitos";
        }
      });
    }
    if (servidor.paginas) {
      servidor.paginas.forEach((pagina) => {
        if (pagina.url === "" || !pagina.url) {
          newErrors.paginas = "Al menos una pagina debe ser ingresada";
        }
      });
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  /* manejo de errores: fin */

  /* Manejo de POST: inicio */

  const handleSubmit = async () => {
    if (validateForm()) {
      postRequest();
    } else {
      Swal.fire({
        title: "Atención",
        text: "Faltan campos por rellenar",
        heightAuto: false,
        toast: true,
        timer: 3000,
        position: "bottom-end",
        showConfirmButton: false,
        confirmButtonColor: "#6A0F49",
        confirmButtonText: "ACEPTAR",
        icon: "warning",
      });
    }
  };

  const postRequest = async () => {
    setIsLoading(true);
    let response;
    if (!servidor.id) {
      response = await services(
        "POST",
        "administradorFuncionarios/nuevoFuncionario",
        { ...servidor, titulo: servidor.titulo }
      );
      // response = await services("POST", "administradorFuncionarios/nuevoFuncionario", {...servidor, titulo: servidor.titulo.id})
    } else {
      response = await services(
        "POST",
        `administradorFuncionarios/modificaFuncionario`,
        { ...servidor, titulo: servidor.titulo }
      );
      // response = await services("POST", `administradorFuncionarios/modificaFuncionario`, {...servidor, titulo: servidor.titulo.id})
    }

    if (response.status === "success") {
      setIsLoading(false);
      Swal.fire({
        title: "Proceso completado",
        text: response.message,
        icon: "success",
        allowOutsideClick: false,
        confirmButtonColor: "#6A0F49",
        confirmButtonText: "ACEPTAR",
      }).then((result) => {
        if (result.isConfirmed) {
          hiddenPopUp();
          actualizarInfoFuncionario();
        }
      });
    } else if (response.status === 422) {
      setIsLoading(false);
      const concatenateValues = (response) => {
        if (response && response.data) {
          const values = Object.values(response.data);
          return Array.from(new Set(values.flat())).join("\n");
        }
        return "";
      };

      const concatenatedValues = concatenateValues(response);
      // "El funcionario ya se encuentra registrado."
      Swal.fire({
        title: "Atención",
        text: concatenatedValues,
        icon: "warning",
        confirmButtonColor: "#6A0F49",
        confirmButtonText: "ACEPTAR",
      });
    } else if (response.status === "info") {
      setIsLoading(false);
      Swal.fire({
        title: "Atención",
        text: response.message,
        icon: "warning",
        confirmButtonColor: "#6A0F49",
        confirmButtonText: "ACEPTAR",
      });
    }
  };

  /* Manejo de POST: fin */

  /* Manejo de Eliminar: inicio */

  const handleDelete = () => {
    deleteServer(idFuncionario);
  };

  const deleteServer = (idFuncionario) => {
    Swal.fire({
      title: "¿Esta seguro de eliminar al Funcionario?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6A0F49",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "¡Si, eliminar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await services(
          "DELETE",
          `administradorFuncionarios/eliminaFuncionario/${idFuncionario}`
        );
        if (response.status === "success") {
          /* Logica de eliminacion */
          /* setServidor({...}) */
          /*Global context */
          Swal.fire({
            title: "Eliminado!",
            text: "El funcionario fue eliminado con exito",
            icon: "success",
            confirmButtonColor: "#6A0F49",
            confirmButtonText: "ACEPTAR",
          }).then((result) => {
            if (result.isConfirmed) {
              hiddenPopUp();
              actualizarInfoFuncionario();
            }
          });
        } else {
          Swal.fire({
            title: "¡Error!",
            text: `${response.message}`,
            icon: "warning",
            confirmButtonColor: "#6A0F49",
            confirmButtonText: "ACEPTAR",
          });
        }
      }
    });
  };
  /* Manejo de Eliminar: final  */

  const actualizarInfoFuncionario = async () => {
    let dataAPI;

    setStatePublicServerAdmin({ ...statePublicServerAdmin, loading: true });
    if (idOficina) {
      dataAPI = await services(
        "GET",
        `funcionarios/mostrarOficina/${idOficina}`
      );
    } else {
      dataAPI = await services(
        "GET",
        `funcionarios/mostrarOficina/${idOficina}`
      );
    }

    if (dataAPI.status === "success") {
      setStatePublicServerAdmin({
        ...statePublicServerAdmin,
        infoOfi: dataAPI.data.oficina,
        infoFun: dataAPI.data.funcionario,
        loading: false,
      });
    }
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <>
      <OverLayIU onClick={hiddenPopUp} />
      {isLoading ? (
        <GifLoader /> // Componente de carga
      ) : (
        <div className="wizard wizard-funcionario">
          <div className="d-flex justify-content-end ">
            <div className="cursor-pointer" onClick={hiddenPopUp}>
              <IconUI>
                <GrClose />
              </IconUI>
            </div>
          </div>

          <h3 className="text-bold">
            {idFuncionario ? "Editar Funcionario" : "Añadir Funcionario"}
          </h3>
          <div className="d-flex-inline justify-content-center mx-auto my-3">
            <div className="d-flex mx-auto justify-content-around align-items-center h-50">
              {/* <div className='w-25'>
                            <IconUI size={'150px'} color={'#6A0F49'} >
                                <BiUserCircle />
                            </IconUI>
                        </div> */}

              <div className="d-flex flex-column w-50 h-75 justify-content-around align-items-center">
                <div className="d-flex w-100 justify-content-end mb-3">
                  <div className="w-100 mb-2 ">
                    <CssTextField
                      required
                      className="w-100"
                      id="nombre"
                      name="nombre"
                      label="Nombre"
                      value={servidor.nombre ?? ""}
                      error={!!errors.nombre}
                      helperText={errors.nombre || ""}
                      inputProps={{
                        maxLength: 100,
                      }}
                      size="small"
                      onChange={handleInputChange}
                      sx={{}}
                    />
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-end mb-3">
                  <div className="w-100 mb-2 ">
                    <CssTextField
                      required
                      className="w-100"
                      id="apellido_paterno"
                      name="apellido_paterno"
                      label="Apellido Paterno"
                      value={servidor.apellido_paterno ?? ""}
                      error={!!errors.apellido_paterno}
                      helperText={errors.apellido_paterno || ""}
                      inputProps={{
                        maxLength: 100,
                      }}
                      size="small"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-end mb-3">
                  <div className="w-100 mb-2 ">
                    <CssTextField
                      className="w-100"
                      id="apellido_materno"
                      name="apellido_materno"
                      label="Apellido Materno"
                      value={servidor.apellido_materno ?? ""}
                      inputProps={{
                        maxLength: 100,
                      }}
                      size="small"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-end mb-3">
                  <div className="w-100 mb-2 ">
                    <CssTextField
                      className="w-100"
                      id="curp"
                      name="curp"
                      label="CURP"
                      value={servidor.curp ?? ""}
                      inputProps={{
                        maxLength: 18,
                      }}
                      size="small"
                      onChange={handleInputChange}
                      error={!!errors.curp}
                      helperText={errors.curp || ""}
                    />
                  </div>
                </div>

                <div className="d-flex w-100 justify-content-end mb-3">
                  <div className="w-100 mb-2 ">
                    <CssTextField
                      className="w-100"
                      id="rfc"
                      name="rfc"
                      label="RFC"
                      value={servidor.rfc ?? ""}
                      inputProps={{
                        maxLength: 13,
                      }}
                      size="small"
                      onChange={handleInputChange}
                      error={!!errors.rfc}
                      helperText={errors.rfc || ""}
                    />
                  </div>
                </div>

                <div className="d-flex w-100 justify-content-end mb-3">
                  <div className="w-100 mb-2">
                    <CssTextField
                      required
                      className="w-100"
                      margin="dense"
                      id="titulo"
                      name="titulo"
                      value={servidor.titulo ?? ""}
                      error={!!errors.titulo}
                      helperText={errors.titulo || ""}
                      select
                      label="Titulo"
                      SelectProps={{
                        native: false,
                      }}
                      onChange={(event) => handleInputChange(event)}
                      style={{ width: "100% !important" }}
                    >
                      {state.catTitulos.map((option, index) => (
                        <MenuItem
                          key={option.id ?? index}
                          value={option.id ?? ""}
                        >
                          {option.titulo}
                        </MenuItem>
                      ))}
                    </CssTextField>
                    {/* <Autocomplete className='w-100'
                                        value={servidor.titulo}
                                        // onChange={(e, newValue)=>{ handleTitulo(e, newValue) }}
                                        onChange={(event, newValue) => {
                                            if (typeof newValue === 'string') {
                                            // timeout to avoid instant validation of the dialog's form.
                                            setTimeout(() => {
                                                toggleOpen(true);
                                                setDialogValue({
                                                titulo: newValue,

                                                });
                                            });
                                            } else if (newValue && newValue.inputValue) {
                                            toggleOpen(true);
                                            setDialogValue({
                                                titulo: newValue.inputValue,
                                            });
                                            } else {
                                            setValueNuevoTitulo(newValue);
                                            handleTitulo(event, newValue)
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            const isExisting = options.some((option) => inputValue === option.titulo);
                                            if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                inputValue: params.inputValue,
                                                titulo: `Crear "${inputValue}"`,
                                            });
                                            }

                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="tituloAutocomplete"
                                        options={state.catTitulos}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                            return option;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (option.inputValue) {
                                            return option.inputValue;
                                            }
                                            // Regular option
                                            return option.titulo;
                                        }}
                                        renderOption={(props, option) => <li {...props} value={option.id}>{option.titulo}</li>}
                                        sx={{ width: 300 }}
                                        freeSolo
                                        renderInput={(params) => (
                                            <CssTextField
                                                    required
                                                    error={!!errors.titulo}
                                                    helperText={errors.titulo || ''}
                                                    {...params}
                                                    label="Titulo"
                                                    SelectProps={{
                                                        native: false,
                                                    }}
                                                    style={{ width: '100% !important' }}
                                                >
                                            </CssTextField>
                                    )}
                                    />
                                    <Dialog open={open} onClose={handleClose}>
                                        <form onSubmit={handleSubmitTitulo}>
                                        <DialogTitle>Agrega un nuevo Titulo</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                            ¿No se encuentra el Titulo que buscas? Añádelo
                                            </DialogContentText>
                                            <TextField className='w-100'
                                            autoFocus
                                            margin="dense"
                                            id="name"
                                            value={dialogValue.titulo}
                                            onChange={(event) =>
                                                setDialogValue({
                                                ...dialogValue,
                                                titulo: event.target.value,
                                                })
                                            }
                                            label="Titulo"
                                            type="text"
                                            variant="standard"
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose}>Cancelar</Button>
                                            <Button type="submit">Añadir</Button>
                                        </DialogActions>
                                        </form>
                                    </Dialog> */}
                  </div>
                </div>

                <div className="d-flex w-100 justify-content-end mb-3">
                  <div className="w-100 mb-2 ">
                    <CssTextField
                      required
                      className="w-100"
                      margin="dense"
                      id="cargo"
                      name="cargo"
                      value={servidor.cargo ?? ""}
                      error={!!errors.cargo}
                      helperText={errors.cargo || ""}
                      select
                      label="Cargo"
                      SelectProps={{
                        native: false,
                      }}
                      onChange={(e) => handleInputChange(e)}
                      style={{ width: "100% !important" }}
                    >
                      {state.catCargos.map((option, index) => (
                        <MenuItem
                          key={option.id ?? index}
                          value={option.id ?? ""}
                        >
                          {option.nombre}
                        </MenuItem>
                      ))}
                    </CssTextField>
                  </div>
                </div>
              </div>
            </div>

            {/* INICIO DE SECCIÓN DE TELEFENOS, MAILS, PAGINAS */}
            <div className="justify-content-center">
              {/* Boton de agregar telefonos */}

              <div className=" d-flex mt-1">
                <button
                  className=" pill pill-little"
                  onClick={() => {
                    servidor.telefonos.push({
                      id_telefono: null,
                      numero: "",
                      privacidad: "",
                    });
                    setServidor({ ...servidor });
                  }}
                >
                  <IconUI size={20}>
                    <BiPlus />
                  </IconUI>
                  Agregar Telefono
                </button>
              </div>

              {/* Arreglo de Telefonos, extenciones y marcaciones cortas */}
              <div
                className="d-flex flex-column "
                style={{ width: "80%", margin: "auto" }}
              >
                <div className="d-flex flex-column align-self-start">
                  {servidor.telefonos &&
                    servidor.telefonos.map((telefono, i) => {
                      return (
                        <Fragment key={i}>
                          <div className="d-flex">
                            <div
                              className="d-flex text-guinda me-2 "
                              style={{ width: "150px" }}
                            >
                              <CssTextField
                                required
                                value={telefono.privacidad ?? ""}
                                className="col-md"
                                margin="dense"
                                id="outlined-select-currency-native"
                                select
                                label="Privacidad"
                                SelectProps={{
                                  native: false,
                                }}
                                size="small"
                                onChange={(e) => handlePrivacyTelefono(e, i)}
                                style={{ width: "100% !important" }}
                              >
                                {state.catPrivacidad.map((option, index) => (
                                  <MenuItem
                                    key={option.id ?? index}
                                    value={option.id ?? ""}
                                  >
                                    {option.tipo ?? null}
                                  </MenuItem>
                                ))}
                              </CssTextField>
                            </div>

                            <div
                              className="d-flex flex-column me-2"
                              style={{ width: "300px" }}
                            >
                              <CssTextField
                                required
                                id={`telefono_${i} `}
                                name={`telefono_${i} `}
                                label="Teléfono (10 Dígitos)"
                                size="small"
                                margin="dense"
                                value={servidor.telefonos[i].numero ?? null}
                                error={!!errors.telefonos}
                                helperText={errors.telefonos || ""}
                                inputProps={{
                                  maxLength: 10,
                                }}
                                onChange={(evt) => {
                                  servidor.telefonos[i].numero =
                                    evt.currentTarget.value;
                                  setServidor({ ...servidor });
                                }}
                                onKeyPress={onlyNumberPhone}
                              />
                            </div>

                            {/* Quitar todo el arreglo de numeros */}
                            <div
                              className=" d-flex m-1 ms-3"
                              onClick={() => {
                                servidor.telefonos.splice(i, 1);
                                setServidor({ ...servidor });
                              }}
                            >
                              <IconUI size={"30px"} color={"#6A0F49"}>
                                <AiOutlineMinusCircle />
                              </IconUI>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                </div>
              </div>

              {/* Boton de Emails */}

              <div className=" d-flex mt-1">
                <button
                  className=" pill pill-little"
                  onClick={() => {
                    const inputs = servidor.emails;
                    inputs.push({ id_email: null, email: "" });
                    setServidor({ ...servidor, emails: inputs });
                  }}
                >
                  <IconUI size={20}>
                    <BiPlus />
                  </IconUI>
                  Agregar Email
                </button>
              </div>

              {/* Arreglo de emails */}

              <div
                className="d-flex flex-column "
                style={{ width: "80%", margin: "auto" }}
              >
                {servidor.emails &&
                  servidor.emails.map((item, index) => {
                    return (
                      <div
                        className="d-flex align-self-start d-flex justify-content-around"
                        key={index}
                      >
                        <div
                          className="d-flex flex-column  me-2 w-80"
                          style={{ width: "470px" }}
                        >
                          <CssTextField
                            required
                            id={`email_${index}`}
                            name={`email_${index}`}
                            label="Correo"
                            size="small"
                            margin="dense"
                            value={item.email ?? ""}
                            error={!!errors.emails}
                            helperText={errors.emails || ""}
                            inputProps={{
                              maxLength: 100,
                            }}
                            onChange={(e) => handleEmails(e, index)}
                          />
                        </div>

                        {index === 0 ? (
                          <></>
                        ) : (
                          <div
                            className="w-10 d-flex mt-1"
                            onClick={() => {
                              const inputs = servidor.emails;
                              if (inputs.length > 1) {
                                inputs.pop(1);
                                setServidor({ ...servidor, emails: inputs });
                              }
                            }}
                          >
                            {
                              <IconUI size={"30px"} color={"#6A0F49"}>
                                <AiOutlineMinusCircle />
                              </IconUI>
                            }
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>

              {/* Boton de paginas */}

              <div className=" d-flex mt-1">
                <button
                  className=" pill pill-little"
                  onClick={() => {
                    servidor.paginas.push({
                      id_pagina: null,
                      url: "",
                      red: "",
                      privacidad: "",
                    });
                    setServidor({ ...servidor });
                  }}
                >
                  <IconUI size={20}>
                    <BiPlus />
                  </IconUI>
                  Agregar Pagina
                </button>
              </div>

              {/* Arreglo de paginas */}

              <div
                className="d-flex flex-column "
                style={{ width: "80%", margin: "auto" }}
              >
                {servidor.paginas &&
                  servidor.paginas.map((item, index) => {
                    return (
                      <div className="d-flex align-self-start" key={index}>
                        <div
                          className="d-flex flex-column  me-2"
                          style={{ width: "30%" }}
                        >
                          <CssTextField
                            required
                            value={item.privacidad ?? ""}
                            className="col-md"
                            margin="dense"
                            id="outlined-select-currency-native"
                            select
                            label="Privacidad"
                            SelectProps={{
                              native: false,
                            }}
                            size="small"
                            style={{ width: "100% !important" }}
                            onChange={(e) => handlePrivacyPagina(e, index)}
                          >
                            {state.catPrivacidad.map((option, index) => (
                              <MenuItem
                                key={option.id ?? index}
                                value={option.id ?? null}
                              >
                                {option.tipo ?? null}
                              </MenuItem>
                            ))}
                          </CssTextField>
                        </div>

                        <div
                          className="d-flex flex-column  me-2"
                          style={{ width: "30%" }}
                        >
                          <CssTextField
                            required
                            className="col-md"
                            margin="dense"
                            id="outlined-select-currency-native"
                            select
                            value={item.red ?? ""}
                            label="Redes"
                            SelectProps={{
                              native: false,
                            }}
                            size="small"
                            onChange={(e) => handleRedes(e, index)}
                            style={{ width: "100% !important" }}
                          >
                            {state.catRedes.map((option, index) => (
                              <MenuItem
                                key={option.id ?? index}
                                value={option.id ?? null}
                              >
                                {option.tipo}
                              </MenuItem>
                            ))}
                          </CssTextField>
                        </div>
                        <div
                          className="d-flex flex-column  me-2"
                          style={{ width: "470px" }}
                        >
                          <CssTextField
                            required
                            id={`pagina_${index}`}
                            name={`pagina_${index}`}
                            label="Pagina Web"
                            size="small"
                            margin="dense"
                            value={servidor.paginas[index].url ?? null}
                            error={!!errors.paginas}
                            helperText={errors.paginas || ""}
                            inputProps={{
                              maxLength: 100,
                            }}
                            onChange={(evt) => {
                              servidor.paginas[index].url =
                                evt.currentTarget.value;
                              setServidor({ ...servidor });
                            }}
                          />
                        </div>

                        <div
                          className=" d-flex mt-1"
                          onClick={() => {
                            servidor.paginas.splice(index, 1);
                            setServidor({ ...servidor });
                          }}
                        >
                          <IconUI size={"30px"} color={"#6A0F49"}>
                            <AiOutlineMinusCircle />
                          </IconUI>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            {/* FIN DE SECCIÓN DE TELEFENOS, MAILS, PAGINAS */}
          </div>

          <div className="d-flex justify-content-center">
            {idFuncionario ? (
              <button
                className="pill-red"
                onClick={handleDelete}
                disabled={idFuncionario == null}
              >
                <IconUI size={25}>
                  <RiCloseFill />
                </IconUI>
                <h6 className="pt-2">Eliminar</h6>
              </button>
            ) : (
              <button className="pill-red" onClick={hiddenPopUp}>
                <IconUI size={25}>
                  <RiCloseFill />
                </IconUI>
                <h6 className="pt-2">Cancelar</h6>
              </button>
            )}

            <button className="pill-green " onClick={handleSubmit}>
              <IconUI size={25}>
                <RiSave3Fill />
              </IconUI>
              <h6 className="pt-2">Guardar</h6>
            </button>
          </div>
        </div>
      )}
    </>
  );
}
