import { useContext, useEffect, useState } from 'react';
import { services } from '../services/api';
import { useParams } from 'react-router-dom';

import IconUI from '../components/ui/icon_ui'
import { FiMail } from "react-icons/fi";
import Location from '../assets/img/card/Location.svg'
import Phone from '../assets/img/card/Phone.svg'
import PhoneMC from '../assets/img/card/PhoneMC.svg'
import WEB from '../assets/img/card/WEB.svg'
import { generateKey } from '../utils/inputsRules';

import User from '../assets/img/card/User.svg'
import GifLoader from "../components/ui/gifLoader"

import { GlobalContext } from '../context/global_context';
import { useHistory } from 'react-router-dom';


export function PublicServerPrivateView() {

    const { idOfi } = useContext(GlobalContext)
    const history = useHistory()
    const { idOficina } = useParams()


    const [state, setState] = useState({
        infoOfi: [],
        infoFun: [],
        loading: false
    })


    useEffect(async () => {

        let isMounted = true
        if (isMounted) {
            let dataAPI

            setState({ ...state, loading: true })

            if (idOfi) {
                dataAPI = await services("GET", `funcionarios/mostrarOficina/${idOfi}`)
            }
            else {
                dataAPI = await services("GET", `funcionarios/mostrarOficina/${idOficina}`)
            }

            if (dataAPI.status === "success") {
                setState({ ...state, infoOfi: dataAPI.data.oficina, infoFun: dataAPI.data.funcionario, loading: false })
            }
            console.log(state.infoOfi.emails)

        }

        return () => {
            isMounted = false
        }
    }, [])

    return (
        <>
            {state.loading === true ?
                <GifLoader />
                :
                <div id="publicServer" className='publicServer'>
                    <div className="container text-center ">
                        <div className="d-block justify-content-center">
                            <div className="pt-3">
                                <h2 className="text-center text-large text-secondary">
                                    Bienvenido al Directorio Estatal de Servidores Públicos
                                </h2>
                            </div>
                            <div>

                                <div className="mb-4 row row-cols-1 row-cols-md-1 g-4 ">
                                    <div className="col ml-4">
                                        <div className="card card--rounded ">
                                            <div className="card-body">
                                                <div className="d-inline-flex pb-4 input-group justify-content-center ">
                                                    <div className='col-2-justify-content-center col-md-2 mb-2'>
                                                        <img src={User} style={{ height: 100, width: 100 }} alt="user logo" />
                                                    </div>
                                                    <div className='text-md-start text-center'>
                                                        <h4 className='text-bolder'>{state.infoOfi.oficina}</h4>
                                                        <h5 className=" text-bolder card-title">{state.infoFun?.titular ?? ""}</h5>
                                                        <h6>{(state.infoFun?.cargo ? state.infoOfi?.cargo : null)}</h6>
                                                    </div>
                                                </div>

                                                <div className="d-inline-flex pb-4  input-group text-center">
                                                    <div className='col-2'>
                                                        <img src={Location} style={{ height: 25, width: 25 }} alt="location logo" />
                                                    </div>
                                                    <div className='col-8 text-start'>
                                                        <h6 className='text-bold'>{state.infoOfi.direccion ? state.infoOfi.direccion : "Sin dirección"}</h6>
                                                    </div>
                                                </div>

                                                <div className="d-inline-flex pb-4  input-group text-center">
                                                    <div className='col-2  text-guinda'>
                                                        <IconUI size={'25px'}>
                                                            <FiMail />
                                                        </IconUI>
                                                    </div>
                                                    <div className='d-inline-flex col-8 text-start'>
                                                        <div className='text-bold'>Correo:
                                                        </div>
                                                        <div>
                                                            {state.infoOfi ?
                                                                state.infoOfi.emails?.length !== 0 ?
                                                                    state.infoOfi.emails?.map((item) => {
                                                                        return (
                                                                            item.length < 1 ?
                                                                                <div key={generateKey}>
                                                                                    sin correo
                                                                                </div>
                                                                                :
                                                                                <div key={generateKey}>
                                                                                    {'\n' + item + ' \b '}
                                                                                </div>
                                                                        )
                                                                    })
                                                                    :
                                                                    <div className='ms-3'> sin correo</div>
                                                                :
                                                                <div className='ms-3'> sin correo</div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-inline-flex pb-4 justify-content-right input-group text-center">
                                                    <div className='col col-md-2'>
                                                        <img src={Phone} style={{ height: 25, width: 25 }} alt="phone logo" />
                                                    </div>
                                                    <div className='d-inline-flex col col-md-4 text-start '>
                                                        <div >
                                                            <div className='text-bold'>
                                                                Teléfono:
                                                            </div>
                                                            {state.infoOfi.telefono ?
                                                                state.infoOfi.telefono.map((item) => {
                                                                    return (
                                                                        item.numero.length < 1 ?
                                                                            <div key={generateKey()}>
                                                                                -
                                                                            </div>
                                                                            :
                                                                            <div key={generateKey()}>
                                                                                {'\n' + item.numero + '\n \b -'}
                                                                            </div>
                                                                    )
                                                                })
                                                                :
                                                                <div key={generateKey()}> sin numero</div>
                                                            }
                                                        </div>

                                                        <div>
                                                            <div className='ms-3 text-bold'>
                                                                Extension:
                                                            </div>
                                                            {state.infoOfi.telefono ?
                                                                state.infoOfi.telefono.map((item) => {
                                                                    return (
                                                                        item.extensiones.length < 1 ?
                                                                            <div key={generateKey()}>
                                                                                -
                                                                            </div>
                                                                            :
                                                                            <div className='ms-3' key={generateKey()}>
                                                                                {item.extensiones + '\n '}
                                                                            </div>
                                                                    )
                                                                })
                                                                :
                                                                <div key={generateKey()} className='ms-3' > sin numero</div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-2 text-center'>
                                                        <img src={PhoneMC} style={{ height: 25, width: 25 }} alt="phonemc logo" />
                                                    </div>
                                                    <div className='col-4 text-start'>
                                                        <div className='text-bold'>
                                                            MC:
                                                        </div>
                                                        {state.infoOfi.telefono ?
                                                            state.infoOfi.telefono.map((item) => {
                                                                return (
                                                                    item.marcacion_corta.length < 1 ?
                                                                        <div key={generateKey()}>
                                                                            -
                                                                        </div>
                                                                        :
                                                                        <div className='ps-5' key={generateKey()}>
                                                                            {item.marcacion_corta + '\n '}
                                                                        </div>
                                                                )
                                                            })
                                                            :
                                                            <div> sin numero</div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="d-inline-flex pb-4 justify-content-right input-group text-center">
                                                    <div className='col-2 justify-content-right'>
                                                        <img src={WEB} style={{ height: 25, width: 25 }} alt="web logo" />
                                                    </div>
                                                    {state.infoOfi.url_web ?
                                                        <div className='col-4 text-start'>
                                                            {state.infoOfi.url_web.map((url) => {
                                                                return <p><a className='text-bold' href={url}>{url}</a></p>
                                                            })}
                                                        </div>
                                                        :
                                                        <div className='col-4 text-start text-bold'>
                                                            Sin Página Web
                                                        </div>

                                                    }
                                                </div>

                                                <div>
                                                    <hr />
                                                    <h3> Datos Privados</h3>

                                                    <div className="d-inline-flex pb-4 justify-content-right input-group text-center">
                                                        <div className='col-2'>
                                                            <img src={Phone} style={{ height: 25, width: 25 }} alt="phone logo" />
                                                        </div>
                                                        <div className='d-flex col-4 text-start '>
                                                            <div >
                                                                <div className='text-bold'>
                                                                    Teléfono:
                                                                </div>
                                                                {state.infoFun ?
                                                                    state.infoFun.numero?.length !== 0 ?
                                                                        state.infoFun.numero?.map((item) => {
                                                                            return (
                                                                                item.length < 1 ?
                                                                                    <div key={generateKey}>
                                                                                        sin numero
                                                                                    </div>
                                                                                    :
                                                                                    <div key={generateKey}>
                                                                                        {'\n' + item + ' \b '}
                                                                                    </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        <div className='ms-3'> sin numero</div>
                                                                    :
                                                                    <div className='ms-3'> sin numero</div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='col-2 justify-content-right text-guinda'>
                                                            <IconUI size={'25px'}>
                                                                <FiMail />
                                                            </IconUI>
                                                        </div>
                                                        <div >
                                                            <div className='text-bold text-start'>
                                                                Correo:
                                                            </div>
                                                            {state.infoFun ?
                                                                state.infoFun.email?.length !== 0 ?
                                                                    state.infoFun.email?.map((item) => {
                                                                        return (
                                                                            item.length < 1 ?
                                                                                <div key={generateKey}>
                                                                                    sin correo
                                                                                </div>
                                                                                :
                                                                                <div key={generateKey}>
                                                                                    {'\n' + item + ' \b '}
                                                                                </div>
                                                                        )
                                                                    })
                                                                    :
                                                                    <div className='ms-3'> sin correo</div>
                                                                :
                                                                <div className='ms-3'> sin correo</div>
                                                            }
                                                        </div>
                                                    </div>


                                                </div>

                                                <div>
                                                    <iframe width="90%" height="500" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={`https://maps.google.com/maps?width=100%25&height=600&hl=en&q=${state.infoOfi.longitud},${state.infoOfi.latitud}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}>
                                                        <a href="https://www.maps.ie/distance-area-calculator.html">distance maps</a>
                                                    </iframe>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}