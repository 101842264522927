import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import jwt_decode from "jwt-decode";


const PrivateRoute = ({children, ...propierties}) => {
    const token = localStorage.getItem('token');
    
    if (token) {
        const user= jwt_decode(token);
        if(user.user_type==='funcionario' || user.user_type==='admin'){
            return <Route {...propierties}>{children}</Route>
        }
    } else {
        return <Redirect to="/login" />
    }
}

export default PrivateRoute;