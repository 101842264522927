// import logoh from "../assets/img/logoh.png";
import logoh from "../assets/img/Logo200.png";
// import logoh from '../assets/img/Veda Horizontal.png'
import logov from "../assets/img/logov.png";
// import logov from '../assets/img/Veda Vertical.png'
import { useHistory } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../context/global_context";
import { BiLogOut } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import { useLocation } from "react-router-dom";

import jwt_decode from "jwt-decode";

import BackArrow from "./ui/backArrow";

export const HeaderComponent = ({ onClose }) => {
  const { icon, setIcon } = useContext(GlobalContext);
  const { iconAd, setIconAd } = useContext(GlobalContext);
  const history = useHistory();
  const location = useLocation();

  const deleteToken = () => {
    localStorage.clear();
    setIcon(false);
    history.replace("/");
  };

  const inicio = () => {
    const token = localStorage.getItem("token");

    if (token) {
      const user = jwt_decode(token);
      if (user.user_type === "admin") {
        history.replace("/administradorDependencias");
      } else {
        history.replace("/inicioFuncionario");
      }
    } else {
      history.replace("/");
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const token = localStorage.getItem("token");
      if (token) {
        const user = jwt_decode(token);
        if (user.user_type === "admin") {
          setIconAd(true);
        } else {
          setIcon(true);
        }
      } else {
        setIcon(false);
        setIconAd(false);
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <div
        id="header"
        className="header headerEstilos py-1 shadow-sm bg-white boderBottom--Rosa"
      >
        <div className="d-flex flex-row ">
          <div className=" col  ">
            <img
              onClick={() => {
                inicio();
              }}
              className=" d-none d-md-block ms-3 mt-2 logo-header cursor-pointer"
              src={logoh}
              alt="logo"
            />

            <img
              onClick={() => {
                inicio();
              }}
              className="d-block d-md-none ms-3 mt-2 logo-header cursor-pointer"
              src={logov}
              alt="logo"
              style={{ height: 100, width: 75 }}
            />
          </div>

          <div className=" col ">
            <p className="mt-4 text-gray  text-center">
              DIRECTORIO ESTATAL DE SERVIDORES PÚBLICOS
            </p>
          </div>

          <div className=" col ">
            {icon ? (
              <div className="me-3 position-absolute end-0 cursor-pointer ">
                <BiLogOut
                  className="text-morado mt-3"
                  style={{ height: 50, width: 50 }}
                  onClick={() => deleteToken()}
                />
              </div>
            ) : (
              <div></div>
            )}

            {iconAd ? (
              <div
                className="mt-2 me-3 position-absolute end-0 cursor-pointer"
                onClick={() => {
                  onClose();
                }}
              >
                <button className="pill">
                  <GiHamburgerMenu
                    className="text-morado "
                    style={{ height: 30, width: 30 }}
                  />
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <BackArrow />
    </>
  );
};
