import { useContext, useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import IconUI from "../components/ui/icon_ui";
import { services } from "../services/api";
import escudo from "../assets/img/Escudo.svg";
// import escudo from "../assets/img/EscudoGris.svg";
import GifLoader from "../components/ui/gifLoader";
import { GlobalContext } from "../context/global_context";
import { useHistory } from "react-router-dom";

export function HomeView() {
  const { setIdDep, setPalabra } = useContext(GlobalContext);

  const history = useHistory();

  const [state, setState] = useState({
    info: [],
    loading: false,
  });

  const handleKeyDown = (event) => {
    const getValue = document.getElementById("palabra").value;
    if (event.key === "Enter") {
      setPalabra(getValue);
      history.push("/todasOficinas");
    }
  };

  useEffect(async () => {
    let isMounted = true;
    if (isMounted) {
      setState({ ...state, loading: true });
      let dataAPI = await services("GET", "publico/masBuscadas");

      if (dataAPI.status === "success") {
        setState({ ...state, info: dataAPI.data, loading: false });
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {state.loading === true ? (
        <GifLoader />
      ) : (
        <div id="home" className="home">
          <div className="container text-center ">
            <div className="d-block justify-content-center">
              <div className="pt-3">
                <h2 className="text-center text-large text-secondary">
                  Bienvenido al Directorio Estatal de Servidores Públicos
                </h2>
              </div>
              <div className="py-4">
                <h6 className="text-center text-large text-tertiary">
                  Por favor, ingresa nombre de la dependencia, oficina, teléfono
                  o marcación corta
                </h6>
              </div>
              <div>
                <div className="d-block pb-4 justify-content-center input-group mb-3 text-center ">
                  <input
                    id="palabra"
                    type="text"
                    className="form-input text-center"
                    aria-describedby="button-search"
                    onKeyDown={handleKeyDown}
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="button-search "
                    onClick={() => {
                      const getValue = document.getElementById("palabra").value;
                      setPalabra(getValue);
                      history.push("/todasOficinas");
                    }}
                  >
                    <IconUI className="justify-content-center" size={"31px"}>
                      <BiSearch />
                    </IconUI>
                  </button>
                </div>

                <div className="mb-4 row row-cols-1 row-cols-md-5 g-4">
                  {state.info.map((item, i) => {
                    return (
                      <div className="col " key={i}>
                        <div
                          className="card card-img-top card--rounded h-100 cursor-pointer"
                          onClick={() => {
                            setIdDep(item.id);
                            history.push(`/dependencia/${item.id}`);
                          }}
                        >
                          <img
                            className="img-fluid"
                            src={escudo}
                            style={{ height: 100 }}
                          />
                          <div className="card-body">
                            <p className="card-text text-bolder text-morado text-medium">
                              {item.dependencia}
                            </p>
                          </div>
                          <p className="bottomCard text-bold text-gray text-small">
                            GOBIERNO DE MICHOACÁN
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="mb-4 row justify-content-md-center">
                  <button
                    className="pill col col-lg-2"
                    type="button"
                    id="button-addon2"
                    onClick={() => {
                      history.push("/dependencias");
                    }}
                  >
                    VER TODAS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
