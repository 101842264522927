import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";
import { NotificacionContextProvider } from "./context/notificacion_context";
import { GlobalContextProvider } from "./context/global_context";
import NotificacionUi from "./components/ui/notificacion_ui";
import PrivateRoute from './components/auth/privateRoute';
import PrivateRouteAdmin from './components/auth/privateRouteAdmin';

import { HomeView } from "./views/home_View";
import { DependenciesView } from "./views/dependencies_View";
import { AllOfficesView } from "./views/allOffices_View";
import { DependencyView } from "./views/dependency_View";
import { OfficeView } from "./views/office_View";
import { PublicServerView } from "./views/publicServer_View";

import { LoginView } from "./views/login_View";
import { AllOfficesPrivateView } from "./views/allOfficesPrivate_View";
import { HomePrivateView } from "./views/homePrivate_View";
import { RecoveryPassView } from "./views/recoveryPass_View";
import { DependenciesPrivateView } from "./views/dependenciesPrivate_View"
import { DependencyPrivateView } from "./views/dependencyPrivate_View";
import { OfficePrivateView } from "./views/officePrivate_View";
import { PublicServerPrivateView } from "./views/publicServerPrivate_View";

import { OfficeAdminView } from "./views/officeAdmin_View";
import { DependenciesAdminView } from "./views/dependenciesAdmin_View";
import { PublicServerAdminView } from "./views/publicServerAdmin_View";


import { HeaderComponent } from "./components/header_component";
import { Menu } from "./components/menu_component";
import { Footer } from "./components/footer_component";
import { NoMatchView } from "./views/noMatcht_View";
import { Error500 } from "./views/error_View";
import NoFoundRoute_View from "./views/noFoundRoute_View";
import { useState } from "react";

/*
  La siguiente linea es necesaria para el servidor de desarrollo.
  Coordinacion DevOps
*/
const baseName = process.env.REACT_APP_ENV === 'development' ? '/directorio' : '/';

function App() {

  const [state, setState] = useState({
    showMenu: false
  })
  
  return (
    <Router basename={baseName}>
      <NotificacionContextProvider >
        <GlobalContextProvider>
            <div className="containerTodo">
            <HeaderComponent  onClose={() => { setState({ ...state, showMenu: true }) }} />
            {state.showMenu ?
                <Menu onClose={() => { setState({ ...state, showMenu: false }) }} />
                :
                null
              }
            <main className="mainContainer w-100 d-flex flex-column">
            <NotificacionUi />
            <div>
              <Switch>

                {/* //rutas publicas */}

                <Route exact path="/">
                  <HomeView />
                </Route>

                <Route exact path="/recuperarContraseña">
                  <RecoveryPassView />
                </Route>

                <Route exact path="/login">
                  <LoginView />
                </Route>

                <Route exact path="/todasOficinas">
                  <AllOfficesView />
                </Route>

                <Route exact path="/dependencias">
                  <DependenciesView />
                </Route>

                <Route path="/dependencia/:idDependencia">
                  <DependencyView />
                </Route>

                <Route path="/oficinas/:idDependencia">
                  <OfficeView />
                </Route>

                <Route path="/servidorPublico/:idOficina">
                  <PublicServerView />
                </Route>

                {/* //Rutas funcionario */}
                <PrivateRoute exact path="/inicioFuncionario">
                  <HomePrivateView />
                </PrivateRoute>

                <PrivateRoute exact path="/todasOficinasPrivadas">
                  <AllOfficesPrivateView />
                </PrivateRoute>

                <PrivateRoute exact path="/privadoDependencias">
                  <DependenciesPrivateView />
                </PrivateRoute>

                <PrivateRoute path="/privadoDependencia/:idDependencia">
                  <DependencyPrivateView />
                </PrivateRoute>

                <PrivateRoute path="/privadoOficinas/:idDependencia">
                  <OfficePrivateView />
                </PrivateRoute>

                <PrivateRoute path="/privadoServidorPublico/:idOficina">
                  <PublicServerPrivateView />
                </PrivateRoute>

                {/* Rutas Administrador */}
                <PrivateRouteAdmin exact path="/administradorDependencias">
                  <DependenciesAdminView />
                </PrivateRouteAdmin>

                <PrivateRouteAdmin path="/administradorOficinas/:idDependencia">
                  <OfficeAdminView />
                </PrivateRouteAdmin>

                <PrivateRouteAdmin path="/administradorServidorPublico/:idOficina">
                  <PublicServerAdminView />
                </PrivateRouteAdmin>

                <Route exact path="*">
                  <NoFoundRoute_View/>
                </Route>

                <Route exact path="/error">
                  <Error500 />
                </Route>

                </Switch>
              </div>
            </main>
            <Footer />
            </div>
            
        </GlobalContextProvider>
      </NotificacionContextProvider>
    </Router>
  );
}

export default App;
