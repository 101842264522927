import { useContext, useEffect, useState } from 'react';
import { services } from '../services/api';
import { useParams } from 'react-router-dom';

import IconUI from '../components/ui/icon_ui'
import { FiMail } from "react-icons/fi";
import Location from '../assets/img/card/Location.svg'
import Phone from '../assets/img/card/Phone.svg'
import PhoneMC from '../assets/img/card/PhoneMC.svg'
import WEB from '../assets/img/card/WEB.svg'

import User from '../assets/img/card/User.svg'
import GifLoader from "../components/ui/gifLoader"

import { GlobalContext } from '../context/global_context';
import { useHistory } from 'react-router-dom';

import { generateKey } from '../utils/inputsRules';


export function PublicServerView() {

    const { idOfi } = useContext(GlobalContext)
    const history = useHistory()
    const { idOficina } = useParams()


    const [state, setState] = useState({
        info: [],
        loading: false
    })


    useEffect(async () => {

        let isMounted = true
        if (isMounted) {
            let dataAPI

            setState({ ...state, loading: true })
            if (idOfi) {
                dataAPI = await services("GET", `publico/mostrarOficina/${idOfi}`)
            }
            else {
                dataAPI = await services("GET", `publico/mostrarOficina/${idOficina}`)
            }

            if (dataAPI.status === "success") {
                setState({ ...state, info: dataAPI.data, loading: false })
            }

        }

        return () => {
            isMounted = false
        }
    }, [])

    return (
        <>
            {state.loading === true ?
                <GifLoader />
                :
                <div id="publicServer" className='publicServer'>
                    <div className="container text-center ">
                        <div className="d-block justify-content-center">
                            <div className="pt-3">
                                <h2 className="text-center text-large text-secondary">
                                    Bienvenido al Directorio Estatal de Servidores Públicos
                                </h2>
                            </div>
                            <div>

                                <div className="mb-4 row row-cols-1 row-cols-md-1 g-4 ">
                                    <div className="col ml-4">
                                        <div className="card card--rounded ">
                                            <div className="card-body ">
                                                <div className="d-inline-flex pb-4 input-group justify-content-center ">
                                                    <div className='col-2-justify-content-center col-md-2 mb-2 '>
                                                        <img src={User}  style={{ height: 100, width: 100 }} alt="user logo" />
                                                    </div>
                                                    <div className='text-md-start text-center'>
                                                        <h4 className='text-bolder'>{state.info.oficina}</h4>
                                                        <h5 className=" text-bolder card-title">{state.info.titular}</h5>
                                                        <h6>{state.info.cargo}</h6>
                                                    </div>
                                                </div>

                                                <div className="d-inline-flex pb-4  input-group text-center">
                                                    <div className='col-2 '>
                                                        <img src={Location} style={{ height: 25, width: 25 }} alt="location logo" />
                                                    </div>
                                                    <div className='col-8 text-start'>
                                                        <h6 className='text-bold'>{state.info.direccion? state.info.direccion : "sin dirección" }</h6>
                                                    </div>
                                                </div>

                                                <div className="d-inline-flex pb-4  input-group text-center">
                                                    <div className='col-2  text-guinda'>
                                                        <IconUI size={'25px'}>
                                                            <FiMail />
                                                        </IconUI>
                                                    </div>
                                                    <div className='d-inline-flex col-8 text-start'>
                                                        <div className='text-bold'>Correo:                                                    
                                                        </div>
                                                        <div>
                                                        {state.info?
                                                            state.info.emails?.map((item) => {
                                                                
                                                                return (
                                                                    item.length < 1 ?
                                                                        <div key={generateKey}>
                                                                            sin correo
                                                                        </div>
                                                                        :
                                                                        <div key={generateKey} className='ms-3'>
                                                                            {'\n' + item + ' \b '}
                                                                        </div>
                                                                )
                                                            })
                                                            :
                                                            <div className='ms-3'> sin correo</div>
                                                        }                                                           
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-inline-flex pb-4 justify-content-start input-group text-center">
                                                    <div className='col col-md-2'>
                                                        <img src={Phone} style={{ height: 25, width: 25 }} alt="phone logo" />
                                                    </div>
                                                    <div className='d-inline-flex col col-md-4 text-start'>
                                                        <div>
                                                            <div className='text-bold'>
                                                            Teléfono:
                                                            </div>
                                                            {state.info.telefono ?
                                                                state.info.telefono.map((item) => {
                                                                    return (
                                                                        item.numero?.length < 1 ?
                                                                            <div key={generateKey()}>
                                                                                -
                                                                            </div>
                                                                            :
                                                                            <div key={generateKey()}>
                                                                                {'\n' + item.numero + '\n \b -'}
                                                                            </div>
                                                                    )
                                                                })
                                                                :
                                                                <div> sin numero</div>
                                                            }
                                                        </div>

                                                        <div>
                                                            <div className='ms-3 text-bold'>
                                                                Extension:
                                                            </div>
                                                            {state.info.telefono ?
                                                                state.info.telefono.map((item) => {
                                                                    return (
                                                                        item.extensiones?.length < 1 ?
                                                                            <div key={generateKey()}>
                                                                                -
                                                                            </div>
                                                                            :
                                                                            <div className='ms-3' key={generateKey()}>
                                                                                {item.extensiones + '\n '}
                                                                            </div>
                                                                    )
                                                                })
                                                                :
                                                                <div className='ms-3'> sin numero</div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col col-md-2 text-center'>
                                                        <img src={PhoneMC} style={{ height: 25, width: 25 }} alt="phonemc logo" />
                                                    </div>
                                                    <div className='col col-md-4 text-start'>
                                                    <h6 className='text-bold'> M.C. </h6>
                                                        {state.info.telefono ?
                                                            state.info.telefono.map((item) => {
                                                                return (
                                                                    item.marcacion_corta?.length < 1 ?
                                                                        <div key={generateKey()}>
                                                                            -
                                                                        </div>
                                                                        :
                                                                        <div key={generateKey()}>
                                                                            {item.marcacion_corta + '\n '}
                                                                        </div>
                                                                )
                                                            })
                                                            :
                                                            <div> sin numero</div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="d-inline-flex pb-4  input-group text-center">
                                                    <div className='col-2 '>
                                                        <img src={WEB} style={{ height: 25, width: 25 }} alt="web logo" />
                                                    </div>
                                                    {state.info.url_web?
                                                    <div className='col-4 text-start'>
                                                        {state.info.url_web.map((url)=>{
                                                            return <p key={generateKey()}><a className='text-bold'href={url}>{url}</a></p>                       
                                                        })}
                                                    </div>
                                                    :
                                                    <div className='col-4 text-start text-bold'>
                                                        Sin Página Web
                                                    </div>
                                                    
                                                    }
                                                    {/* <div className='col-4 text-start'>
                                                        <h6 className='text-bold'>{state.info.url_web}</h6>
                                                    </div> */}
                                                </div>

                                                <div>
                                                    <iframe width="90%" height="500" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={`https://maps.google.com/maps?width=100%25&height=600&hl=en&q=${state.info.longitud},${state.info.latitud}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}>
                                                        <a href="https://www.maps.ie/distance-area-calculator.html">distance maps</a>
                                                    </iframe>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }
        </>

    )
}