import axios from "axios";
import { urlApi } from "./urlAPI";


export const token = () => {
    return window.localStorage.getItem('token');
}

export const config = () => {
    let token = window.localStorage.getItem('token');
    return {
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    }
}

export const settings = () => {
    return {
        headers: {
            "Content-Type": "application/json",
        }
    }
}

export const services = (method, service, body) => {

    switch (method) {
        case "GET":

            let url = `${urlApi}${service}`;
            return axios.get(url, config()).then((r) => {
                return response(r)
            }).catch((err) => {
                return response(err.response);
            });


        case "POST":

            return axios.post(`${urlApi}${service}`, body, config()).then((r) => {
                return response(r);
            }).catch((err) => {
                return response(err.response);
            });


        case "PUT":
            return axios.put(`${urlApi}${service}`, body, config()).then((r) => {
                return response(r);
            }).catch((err) => {
                return response(err.response);
            });

        case "DELETE":
            return axios.delete(`${urlApi}${service}`, config()).then((r) => {
                return response(r);
            }).catch((err) => {
                return response(err.response);
            });
        default:
            break;
    }
}


const response = (r) => {

    if (r === undefined) {
        return false;
    }    
    if (r.status === 200) {
        return r.data
    }
    if(r.status === 304){
        if( r.data.message ){ console.log( "ARCHIVO DE API, CUANDO HAY R.DATA.MESSAGE: ", r.data.message) } else { console.log( "ARCHIVO DE API, CUANDO HAY R.MESSAGE: ", r.message)  }
       return r.data.message ? {status:r.status, data:r.data.message} : { status:r.status, data: r.message }
    }
    if(r.status === 422){
        if( r.data.message ){ console.log( "ARCHIVO DE API, CUANDO HAY R.DATA.MESSAGE: ", r.data.message) } else { console.log( "ARCHIVO DE API, CUANDO HAY R.MESSAGE: ", r.message)  }
       return r.data.message ? {status:r.status, data:r.data.message} : { status:r.status, data: r.message }
    }
    
    if (r.status === 401) {
        window.localStorage.clear();
        window.location.replace('/login');
        return false
    }

    if (r.status === 500) {
        // window.location.replace('/error');
        
        return false
    }

    return { status: r.status, errors: r.data.error }
}