import { useContext, useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { services } from '../services/api';
import { BiSearch } from 'react-icons/bi';
import { BsTelephoneFill, BsTelephone } from 'react-icons/bs';
import IconUI from '../components/ui/icon_ui'
import { GlobalContext } from '../context/global_context';
import { useHistory } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import GifLoader from "../components/ui/gifLoader"
import { generateKey } from '../utils/inputsRules';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';



export function AllOfficesPrivateView() {

    const { setIdOfi, palabra } = useContext(GlobalContext)
    const history = useHistory()

    const [state, setState] = useState({
        info: [],
        loading: false,
    })

    useEffect(async () => {

        let isMounted = true
        if (isMounted) {
            setState({ ...state, loading: true })
            let dataAPI;

            dataAPI = await services("GET", `publico/listasOficinas`)

            if (dataAPI.status === "success") {
                setState({ ...state, info: dataAPI.data, loading: false })
            }
        }

        return () => {
            isMounted = false
        }
    }, [])


    const columns = [
        { id: 'siglas', label: 'Siglas', minWidth: 100 },
        { id: 'nombre', label: 'Dependencia', minWidth: 100 },
        { id: 'oficina', label: 'Oficina', minWidth: 100 },
        { id: 'titular', label: 'Titular', minWidth: 100 },
        { label: 'Teléfono', minWidth: 100 },
        { label: 'Marcación Corta', minWidth: 100 },
        { label: 'Extensiones', minWidth: 100 }
    ];

    const rows = state.info;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const [stateFilter, setStateFilter] = useState({
        filtro: palabra,
        data: rows,
    })


    const filter = () => {
        if (stateFilter.filtro === '') {
            return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).filter((item) => {
                return item.oficina.toLowerCase().includes(stateFilter.filtro.toLowerCase()) ||
                    item.titular.toLowerCase().includes(stateFilter.filtro.toLowerCase()) ||
                    item.nombre.toLowerCase().includes(stateFilter.filtro.toLowerCase()) ||
                    item.siglas.toLowerCase().includes(stateFilter.filtro.toLowerCase()) ||
                    item.telefono[0].numero.toLowerCase().includes(stateFilter.filtro.toLowerCase()) ||
                    item.telefono[0].extensiones.filter((item2) => {
                        return item2.includes(stateFilter.filtro.toLowerCase()) ?
                            item2
                            :
                            false
                    }).includes(stateFilter.filtro.toLowerCase()) ||
                    item.telefono[0].marcacion_corta.filter((item3) => {
                        return item3.includes(stateFilter.filtro.toLowerCase()) ?
                            item3
                            :
                            false
                    }).includes(stateFilter.filtro.toLowerCase())
            })
        }
        else {
            return rows.slice(page * rowsPerPage, rows.length).filter((item) => {
                return item.oficina && item.oficina.toLowerCase().includes(stateFilter.filtro.toLowerCase())
                    || item.titular && item.titular.toLowerCase().includes(stateFilter.filtro.toLowerCase())
                    || item.nombre && item.nombre.toLowerCase().includes(stateFilter.filtro.toLowerCase())
                    || item.siglas && item.siglas.toLowerCase().includes(stateFilter.filtro.toLowerCase())
                    || item.telefono && item.telefono[0].numero && item.telefono[0].numero.toLowerCase().includes(stateFilter.filtro.toLowerCase())
                    || item.telefono && item.telefono[0] && item.telefono[0].extensiones && item.telefono[0].extensiones.filter((item2) => {
                        return item2.includes(stateFilter.filtro.toLowerCase()) ?
                            item2
                            :
                            false
                    }).includes(stateFilter.filtro.toLowerCase())
                    || item.telefono && item.telefono[0] && item.telefono[0].marcacion_corta && item.telefono[0].marcacion_corta.filter((item3) => {
                        return item3.includes(stateFilter.filtro.toLowerCase()) ?
                            item3
                            :
                            false
                    }).includes(stateFilter.filtro.toLowerCase())
            })
        }
    }


    return (
        <>
            {state.loading === true ?
                <GifLoader />
                :
                <div id="office" className='office'>
                    <div className="container text-center ">
                        <div className="d-block justify-content-center">
                            <div className="pt-3">
                                <h2 className="text-center text-large text-secondary">
                                    Bienvenido al Directorio Estatal de Servidores Públicos
                                </h2>
                            </div>
                            <div className="py-4">
                                <h6 className="text-center text-large text-tertiary">
                                    Por favor ingresa oficina, nombre del titular, teléfono o extensión de la de la oficina
                                </h6>
                            </div>
                            <div>
                                <div className="d-inline-flex pb-4 justify-content-center input-group mb-3 text-center ">

                                    <Input
                                        className='form-input text-center'
                                        type='text'
                                        endAdornment={<InputAdornment position="end">
                                            <IconUI size={'31px'}>
                                                <BiSearch />
                                            </IconUI>
                                        </InputAdornment>}
                                        value={stateFilter.filtro} onChange={(e) => {
                                            setStateFilter({ ...stateFilter, filtro: e.target.value })

                                        }
                                        }
                                        disableUnderline
                                    />
                                </div>
                                {
                                    filter().length <= 0 &&
                                    <div className='w-100 justify-content-center d-flex'>
                                        <div className='cta w-100 text-morado'>
                                            <div>
                                            { stateFilter.filtro.length>0 && (<>No se encontraron resultados para</>)}
                                                <br />
                                                {`${stateFilter.filtro.length > 30 ? stateFilter.filtro.slice(0, 30) : stateFilter.filtro}`}
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div>
                                    <TableContainer >
                                        <Table className='table table-striped' >
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={generateKey()}
                                                            align="center"
                                                            style={{ top: 57, minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filter().map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={generateKey()} >
                                                            {columns.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <Fragment key={generateKey()}>
                                                                        {

                                                                            column.label === 'Teléfono' ?
                                                                                <TableCell align="center" key={generateKey()}>
                                                                                    <div >
                                                                                        {
                                                                                            row.telefono ?
                                                                                                row.telefono.map((item) => {

                                                                                                    return (
                                                                                                        item.numero.length < 1 ?
                                                                                                            <div key={generateKey()}>
                                                                                                                -
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div className='d-flex' key={generateKey()}>
                                                                                                                <BsTelephoneFill className='text-morado me-1' />
                                                                                                                {item.numero + ''}
                                                                                                            </div>
                                                                                                    )
                                                                                                })
                                                                                                :
                                                                                                <div key={generateKey()}>
                                                                                                    -
                                                                                                </div>
                                                                                        }
                                                                                    </div>
                                                                                </TableCell>
                                                                                :
                                                                                column.label === 'Marcación Corta' ?
                                                                                    <TableCell align="center" key={generateKey()}>
                                                                                        {
                                                                                            row.telefono ?
                                                                                                row.telefono.map((item) => {
                                                                                                    return (
                                                                                                        item.marcacion_corta.length < 1 ?
                                                                                                            <div key={generateKey()}>
                                                                                                                -
                                                                                                            </div>

                                                                                                            :
                                                                                                            <div key={generateKey()}>
                                                                                                                <BsTelephone className='text-morado me-1' />
                                                                                                                {'\n' + item.marcacion_corta}
                                                                                                            </div>
                                                                                                    )
                                                                                                })
                                                                                                :
                                                                                                <div key={generateKey()}>
                                                                                                    -
                                                                                                </div>
                                                                                        }
                                                                                    </TableCell>
                                                                                    :
                                                                                    column.label === 'Extensiones' ?

                                                                                        <TableCell align="center" key={generateKey()}>
                                                                                            <div>
                                                                                                {
                                                                                                    row.telefono ?
                                                                                                        row.telefono.map((item) => {
                                                                                                            return (
                                                                                                                item.extensiones.length < 1 ?
                                                                                                                    <div key={generateKey()}>
                                                                                                                        -
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div key={generateKey()}>
                                                                                                                        <BsTelephone className='text-morado me-1' />
                                                                                                                        {'\n ' + item.extensiones}
                                                                                                                    </div>
                                                                                                            )
                                                                                                        })
                                                                                                        :
                                                                                                        <div key={generateKey()}>
                                                                                                            -
                                                                                                        </div>
                                                                                                }
                                                                                            </div>
                                                                                        </TableCell>
                                                                                        :
                                                                                        column.label === 'Siglas' ?
                                                                                            <TableCell align="center" key={generateKey()}>
                                                                                                {
                                                                                                    row.siglas ?
                                                                                                        <div>
                                                                                                            {row.siglas}
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div>
                                                                                                            -
                                                                                                        </div>
                                                                                                }
                                                                                            </TableCell>
                                                                                            :
                                                                                            <TableCell className='cursor-pointer' key={generateKey()} align="center" onClick={() => {
                                                                                                setIdOfi(row.id)
                                                                                                history.push(`/privadoServidorPublico/${row.id}`)
                                                                                            }} >
                                                                                                {column.format && typeof value === 'number'
                                                                                                    ? column.format(value)
                                                                                                    : value}
                                                                                            </TableCell>
                                                                        }
                                                                    </Fragment>
                                                                )

                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 50, { label: 'Todas', value: -1 }]}
                                        component="div"
                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        labelRowsPerPage='Filas por Página:'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}