import React, { useContext, useEffect, useState } from "react";
import { services } from "../../services/api";
import IconUI from "../ui/icon_ui";
import { GrClose } from "react-icons/gr";

import GifLoader from "./gifLoader";

import { GlobalContext } from "../../context/global_context";

import { RiSave3Fill, RiCloseFill } from "react-icons/ri";
import OverLayIU from "./overlay_ui";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import MenuItem from "@mui/material/MenuItem";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#6A0F49",
    // color: '#8d949e',
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#6A0F49",
    // borderBottomColor: '#8d949e',
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#6A0F49",
      // borderColor: '#8d949e',
    },
    "&:hover fieldset": {
      borderColor: "#6A0F49",
      // borderColor: '#8d949e',
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6A0F49",
      // borderColor: '#8d949e',
    },
  },
});

export default function PopUpEditDependency({ onclose }) {
  const { infoDep, setInfoDep, idDep } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    cat: [],
  });

  const [form, setForm] = useState({
    id: "",
    nombre: "",
    siglas: "",
    rfc: "",
    descripcion: "",
    upp: "",
    cat_div_gobierno_id: "",
  });

  useEffect(async () => {
    let isMounted = true;
    setIsLoading(true);
    if (isMounted) {
      setState({ ...state, loading: true });
      let dataAPI, dataAPI2;
      dataAPI = await services("GET", `catalogos/tiposGobierno`);
      // debugger;
      dataAPI2 = await services(
        "GET",
        `funcionarios/mostrarDependencia/${idDep}`
      );
      setState({ ...state, cat: dataAPI.data });
      setForm({
        ...form,
        id: dataAPI2.data.dependencia[0].id,
        nombre: dataAPI2.data.dependencia[0].nombre,
        siglas: dataAPI2.data.dependencia[0].siglas,
        rfc: dataAPI2.data.dependencia[0].rfc,
        descripcion: dataAPI2.data.dependencia[0].descripcion,
        upp: dataAPI2.data.dependencia[0].upp,
        cat_div_gobierno_id: dataAPI2.data.dependencia[0].cat_div_gobierno_id,
      });
      setIsLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const actualizarInfoDependencias = async () => {
    setIsLoading(true);
    setInfoDep({ ...infoDep, loading: true });
    let dataAPI = await services("GET", "funcionarios/listarDependencias");

    if (dataAPI.status === "success") {
      setIsLoading(false);
      setInfoDep({ ...infoDep, info: dataAPI.data, loading: false });
    } else if (dataAPI.status === "info") {
      setIsLoading(false);
      setInfoDep({ ...infoDep, loading: true });
    }
  };

  const handleTipo = (event) => {
    setForm({ ...form, cat_div_gobierno_id: event.target.value });
  };

  const hiddenPopUp = () => {
    onclose();
  };

  const sendInfo = async () => {
    let response = await services(
      "POST",
      "dependencias/modificaDependencia",
      form
    );
    setIsLoading(true);
    if (response.status === "success") {
      setIsLoading(false);
      actualizarInfoDependencias();
      Swal.fire({
        title: "Se ha modificado con exito",
        text: response.datos,
        icon: "success",
        confirmButtonColor: "#6A0F49",
        confirmButtonText: "ACEPTAR",
      }).then((result) => {
        if (result.isConfirmed) {
          hiddenPopUp();
        }
      });
    } else if (response.status === "info") {
      setIsLoading(false);
      const concatenateValues = (response) => {
        if (response && response.message) {
          return response.message;
        }
        return "";
      };

      const concatenatedValues = concatenateValues(response);
      // "El funcionario ya se encuentra registrado."
      Swal.fire({
        title: "Atención",
        text: concatenatedValues,
        icon: "warning",
        confirmButtonColor: "#6A0F49",
        confirmButtonText: "ACEPTAR",
      });
    }
    hiddenPopUp();
  };

  /* manejo de errores: principio */
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let newErrors = {};
    const re =
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;

    if (!form.nombre) {
      newErrors.nombre = "El campo Nombre es requerido";
    } else if (form.nombre.length > 100) {
      newErrors.nombre =
        "El campo Nombre debe tener un máximo de 100 caracteres";
    }

    // if (!form.siglas) {
    //     newErrors.siglas = 'El campo Siglas es requerido';
    // } else if (form.siglas.length > 13) {
    //     newErrors.siglas = 'El campo Siglas debe tener un máximo de 13 caracteres';
    // }

    if (form.rfc && form.rfc.length !== 13) {
      newErrors.rfc = "El campo RFC debe tener un mínimo de 13 caracteres";
    } else if (form.rfc) {
      let valido = form.rfc.match(re);
      if (!valido) {
        newErrors.rfc = "El campo RFC no es valido favor de revisar";
      }
    }

    if (!form.cat_div_gobierno_id) {
      newErrors.cat_div_gobierno_id =
        "Debe seleccionar al menos un valor en Tipo de gobierno";
    }

    if (!form.cat_div_gobierno_id) {
      newErrors.cat_div_gobierno_id =
        "Debe seleccionar al menos un valor en Tipo de gobierno";
    }

    // if (!form.descripcion) {
    //     newErrors.descripcion = 'El campo descripcion es requerido';
    // } else if (form.descripcion.length > 600 && form.descripcion.length < 1 ) {
    //     newErrors.descripcion = 'El campo descripcion debe tener un máximo de 600 caracteres';
    // }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  /* manejo de errores: fin */

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      sendInfo();
    } else {
      Swal.fire({
        title: "Atención",
        text: "Faltan campos por rellenar",
        heightAuto: false,
        toast: true,
        timer: 3000,
        position: "bottom-end",
        showConfirmButton: false,
        confirmButtonColor: "#6A0F49",
        confirmButtonText: "ACEPTAR",
        icon: "warning",
      });
    }
  };

  return (
    <>
      <OverLayIU onClick={hiddenPopUp} />
      {isLoading ? (
        <GifLoader /> // Componente de carga
      ) : (
        <div className="wizard">
          <div className="d-flex justify-content-end ">
            <div className="cursor-pointer" onClick={hiddenPopUp}>
              <IconUI>
                <GrClose />
              </IconUI>
            </div>
          </div>

          <h3 className="text-bold">Editar Dependencia</h3>
          <div
            className="d-flex-inline justify-content-center "
            style={{ width: "80%", margin: "auto" }}
          >
            <div className="d-flex align-items-center justify-content-center ">
              <div className="d-flex  flex-column w-100">
                <CssTextField
                  required
                  id="nombre"
                  name="nombre"
                  label="Nombre"
                  size="small"
                  margin="dense"
                  fullWidth
                  value={form.nombre}
                  onChange={(evt) => {
                    setForm({ ...form, nombre: evt.target.value });
                  }}
                  error={!!errors.nombre}
                  helperText={errors.nombre || ""}
                  inputProps={{
                    maxLength: 100,
                  }}
                />

                <div className="d-flex justify-content-between">
                  <CssTextField
                    // required
                    className="col-md me-1"
                    id="siglas"
                    name="siglas"
                    label="Siglas"
                    size="small"
                    margin="dense"
                    value={form.siglas != undefined ? form.siglas : form.siglas}
                    onChange={(evt) => {
                      setForm({ ...form, siglas: evt.target.value });
                    }}
                    // error={!!errors.siglas}
                    // helperText={errors.siglas || ''}
                    inputProps={{
                      maxLength: 13,
                    }}
                  />

                  <CssTextField
                    // required
                    className="col-md me-1"
                    id="rfc"
                    name="rfc"
                    label="RFC"
                    size="small"
                    margin="dense"
                    value={form.rfc != undefined ? form.rfc : form.rfc}
                    onChange={(evt) => {
                      setForm({ ...form, rfc: evt.target.value });
                    }}
                    error={!!errors.rfc}
                    helperText={errors.rfc || ""}
                    inputProps={{
                      maxLength: 13,
                    }}
                  />

                  <CssTextField
                    required
                    className="col-md "
                    style={{ textAlign: "left" }}
                    id="cat_div_gobierno_id"
                    name="cat_div_gobierno_id"
                    value={form.cat_div_gobierno_id} // Agrega el valor seleccionado al estado 'form'
                    margin="dense"
                    label="Tipo de gobierno"
                    select
                    SelectProps={{
                      native: false,
                    }}
                    size="small"
                    onChange={handleTipo}
                    error={!!errors.cat_div_gobierno_id} // Indica si hay un error en el campo 'cat_div_gobierno_id'
                    helperText={errors.cat_div_gobierno_id || ""} // Muestra el mensaje de error correspondiente
                  >
                    {state.cat.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.tipo}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>
                <CssTextField
                  // required
                  id="descripcion"
                  name="descripcion"
                  label="Descripción"
                  multiline
                  rows={4}
                  margin="dense"
                  fullWidth
                  value={
                    form.descripcion != undefined
                      ? form.descripcion
                      : form.descripcion
                  }
                  onChange={(evt) => {
                    setForm({ ...form, descripcion: evt.target.value });
                  }}
                  // error={!!errors.descripcion} // Indica si hay un error en el campo 'descripcion'
                  // helperText={errors.descripcion || ''} // Muestra el mensaje de error correspondiente
                  inputProps={{
                    maxLength: 600,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <button className="pill-red" onClick={hiddenPopUp}>
              <IconUI size={25}>
                <RiCloseFill />
              </IconUI>
              <h6 className="pt-2">Cancelar</h6>
            </button>

            <button className="pill-green " onClick={handleSubmit}>
              <IconUI size={25}>
                <RiSave3Fill />
              </IconUI>
              <h6 className="pt-2">Guardar</h6>
            </button>
          </div>
        </div>
      )}
    </>
  );
}
