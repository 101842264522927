import React, { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  //Logica
  const [idDep, setIdDep] = useState('')
  const [infoDep, setInfoDep] = useState({
    filtro: '',
    info: [],
    loading: false,
  })
  const [idOfi, setIdOfi] = useState('')
  const [infoOfi, setInfoOfi] = useState({
    filtro: '',
    info: [],
    loading: false,
  })
  const [statePublicServerAdmin, setStatePublicServerAdmin] = useState({
    infoOfi: [],
    infoFun: [],
    loading: false
})
  const [infoAdmin, setInfoAdmin] = useState([])
  const [palabra, setPalabra] = useState('')
  const [palabraOfi, setPalabraOfi] = useState('')
  const [icon, setIcon] = useState(false)
  const [iconAd, setIconAd] = useState(false)
  
  return (
    <GlobalContext.Provider
      value={{
        idDep,
        setIdDep,
        infoDep,
        setInfoDep,
        idOfi,
        setIdOfi,
        palabra,
        setPalabra,
        infoOfi,
        setInfoOfi,
        palabraOfi,
        setPalabraOfi,
        infoAdmin,
        setInfoAdmin,
        statePublicServerAdmin,
        setStatePublicServerAdmin,
        icon,
        setIcon,
        iconAd, 
        setIconAd
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
