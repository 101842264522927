import gifLogo1 from "../../assets/img/Logo3.gif";
// import gifLogo1 from "../../assets/img/Logo Anim.gif";
// import { toTop } from '../../utils/animationFunctions'

export default function GifLoader() {
  return (
    <div className="container-gif">
      <div className="d-flex justify-content-center align-items-center h-100">
        <img src={gifLogo1} className="logoGif" />
      </div>
    </div>
  );
}
