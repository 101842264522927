import React, { useContext, useEffect, useState, Fragment } from 'react';
import { BiSearch } from 'react-icons/bi';
import { AiOutlinePlus, AiFillEdit, AiTwotoneDelete } from 'react-icons/ai';
import { BsTelephoneFill, BsTelephone } from 'react-icons/bs';
import { MdLocationPin } from 'react-icons/md';
import IconUI from '../components/ui/icon_ui'
import { GlobalContext } from '../context/global_context';
import { useHistory } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import { services } from '../services/api';
import GifLoader from "../components/ui/gifLoader"
import PopUpDependency from '../components/ui/popUpDependency';
import { generateKey } from '../utils/inputsRules';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Swal from 'sweetalert2';
import PopUpEditDependency from '../components/ui/popUpEditDependency';



export function DependenciesAdminView() {

  const { setIdDep, infoDep, setInfoDep} = useContext(GlobalContext)
  const history = useHistory()


  useEffect(async () => {

    let isMounted = true

    if (isMounted) {
      setInfoDep({ ...infoDep, loading: true })
      let dataAPI = await services("GET", "funcionarios/listarDependencias")

      if (dataAPI.status === "success") {
        setInfoDep({ ...infoDep, info: dataAPI.data, loading: false })
      } else if (dataAPI.status === "info") {
        setInfoDep({ ...infoDep, loading: true })

        Swal.fire({
          title: "Atención",
          text: dataAPI.message,
          icon: "warning",
        })
        history.replace('/')
      }
    }

    return () => {
      isMounted = false
    }
  }, [])

  const columns = [
    { id: 'siglas', label: 'Siglas', minWidth: 50 },
    { id: 'nombre', label: 'Dependencia', minWidth: 75 },
    { label: 'Teléfono', minWidth: 100 },
    { label: 'Marcación Corta', minWidth: 100 },
    { label: 'Editar', minWidth: 100 },
    { label: 'Eliminar', minWidth: 100 },
  ];

  const rows = infoDep.info;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [modal, setModal] = useState(false)
  const showModal = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto' // Opcional: agrega un desplazamiento suave
  });
    setModal(true)
  }

  const hiddenModal = () => {
    setModal(false)
  }

  const [modal2, setModal2] = useState(false)
  const showModal2 = (id) => {
    setIdDep(id)
    setModal2(true)
    window.scrollTo({
      top: 0,
      behavior: 'auto' // Opcional: agrega un desplazamiento suave
  });
  }

  const hiddenModal2 = () => {
    setModal2(false)
  }

  const filter = () => {
    if (infoDep.filtro === '') {
      return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).filter((item) => {
        return item.nombre.toLowerCase().includes(infoDep.filtro.toLowerCase()) ||
          item.siglas.toLowerCase().includes(infoDep.filtro.toLowerCase()) ||
          item.telefono[0].numero.toLowerCase().includes(infoDep.filtro.toLowerCase()) ||
          item.telefono[0].marcacion_corta.filter((item2) => {
            return item2.includes(infoDep.filtro.toLowerCase()) ?
              item2
              :
              false
          }).includes(infoDep.filtro.toLowerCase())
      })
    }

    else {
      return rows.slice(page * rowsPerPage, rows.length).filter((item) => {
        return item.nombre && item.nombre.toLowerCase().includes(infoDep.filtro.toLowerCase())
          || item.siglas && item.siglas.toLowerCase().includes(infoDep.filtro.toLowerCase())
          || item.telefono && item.telefono[0].numero && item.telefono[0].numero.toLowerCase().includes(infoDep.filtro.toLowerCase())
          || item.telefono && item.telefono[1]&& item.telefono[1].numero && item.telefono[1].numero.toLowerCase().includes(infoDep.filtro.toLowerCase())
          || item.telefono && item.telefono[2]&& item.telefono[2].numero && item.telefono[2].numero.toLowerCase().includes(infoDep.filtro.toLowerCase())
          || item.telefono && item.telefono[3]&& item.telefono[3].numero && item.telefono[3].numero.toLowerCase().includes(infoDep.filtro.toLowerCase())
          || item.telefono && item.telefono[0] && item.telefono[0].marcacion_corta && item.telefono[0].marcacion_corta.filter((item2) => {
            return item2.includes(infoDep.filtro.toLowerCase()) ?
              item2
              :
              false
          }).includes(infoDep.filtro.toLowerCase())
      })
    }
  }

  const deleteOffice = (idDependencia) => {

    Swal.fire({
      title: '¿Esta seguro de eliminar Dependencia?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#6A0F49',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: '¡Si, Eliminar!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await services("DELETE", `dependencias/eliminaDependencia/${idDependencia}`)
        if (response.status === "success") {
          /* Logica de eliminacion */
          var index = infoDep.info.map(x => {
            return x.id;
          }).indexOf(idDependencia);

          infoDep.info.splice(index, 1);

          setInfoDep({ ...infoDep, info: infoDep.info })

          Swal.fire(
            {
              title: '¡Eliminado!',
              icon: 'success',
              text: 'La dependencia fue eliminada con exito', 
              confirmButtonColor: '#6A0F49',
              confirmButtonText: 'ACEPTAR'
            }                       
          )
        } else {
          Swal.fire(
            '¡Error!',
            `${response.message}`,
            'success'
          )
        }
      }
    })


  }



  return (
    <>
      {infoDep.loading === true ?
        <GifLoader />
        :
        <div id="dependecy" className='dependency'>
          <div className="container text-center ">
            <div className="d-block justify-content-center">
              <div className="pt-3">
                <h2 className="text-center text-large text-secondary">
                  Bienvenido al Directorio Estatal de Servidores Públicos
                </h2>
              </div>
              <div className="py-4">
                <h6 className="text-center text-large text-tertiary">
                  Por favor ingresa nombre de la dependencia, siglas, teléfono o marcación corta
                </h6>
              </div>
              <div>
                <div className="d-block pb-4 justify-content-center ">

                  <Input
                    className='form-input text-center cursor-pointer'
                    endAdornment={<InputAdornment position="end">
                      <IconUI size={'31px'}>
                        <BiSearch />
                      </IconUI>
                    </InputAdornment>}
                    value={infoDep.filtro} onChange={(e) => setInfoDep({ ...infoDep, filtro: e.target.value })}
                    disableUnderline
                  />

                </div>
                {
                    filter().length<=0 && 
                    <div className='w-100 justify-content-center d-flex'>
                        <div className='cta w-100 text-morado'>
                            <div> 
                            { infoDep.filtro.length>0 && (<>No se encontraron resultados para</>)}
                            <br />
                            {`${infoDep.filtro.length>30 ? infoDep.filtro.slice(0, 30) : infoDep.filtro }`}
                            </div>
                        </div>
                    </div>
                }

                <div className='d-flex justify-content-end ms-3 cursor-pointer' onClick={showModal}>
                  <IconUI size={'30px'}>
                    <AiOutlinePlus className='text-morado' />
                  </IconUI>
                  <h4 className='mt-2 text-morado text-decoration-underline cursor-pointer' >Añadir Dependencia</h4>
                </div>
                {
                  modal === true ?
                    <PopUpDependency onclose={hiddenModal} >
                    </PopUpDependency>
                    :
                    null
                }



                <div>
                  <TableContainer >
                    <Table className='table table-striped'>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={generateKey()}
                              align="center"
                              style={{ top: 57, minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filter().map((row) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={generateKey()} >
                              {columns.map((column,i) => {
                                const value = row[column.id];
                                return (
                                  <Fragment key={generateKey()}>
                                    {

                                      column.label === 'Siglas' ?

                                        <TableCell key={i} className='cursor-pointer' align="center" onClick={() => {
                                          setIdDep(row.id)
                                          history.push(`/administradorOficinas/${row.id}`)
                                        }}>
                                          <div >
                                            {
                                              row.siglas?.length < 1 || row.siglas == null ?
                                                <div>
                                                  -
                                                </div>
                                                :
                                                row.siglas

                                            }
                                          </div>
                                        </TableCell>

                                        :

                                        column.label === 'Editar' ?

                                          <TableCell align="center" key={i}>
                                            <div className='cursor-pointer' onClick={() => { showModal2(row.id, row.nombre, row.siglas, row.rfc, row.descripcion, row.upp, row.cat_div_gobierno_id) }}>
                                              <IconUI size={'25px'}>
                                                <AiFillEdit className='text-morado' />
                                              </IconUI>

                                            </div>

                                          </TableCell>
                                          :

                                          column.label === 'Eliminar' ?

                                            <TableCell align="center" key={i}>
                                              <div className='cursor-pointer' onClick={() => {

                                                deleteOffice(row.id)

                                              }}>
                                                <IconUI size={'25px'}>
                                                  <AiTwotoneDelete className='text-morado' />
                                                </IconUI>

                                              </div>
                                            </TableCell>

                                            :

                                            column.label === 'Localización' ?

                                              <TableCell align="center" key={i}>
                                                <div className='cursor-pointer' onClick={() => {
                                                  window.open("https://maps.google.com?q=" + row.longitud + "," + row.latitud);
                                                }}>
                                                  <MdLocationPin className='text-morado' style={{ height: 25, width: 25 }} />
                                                </div>
                                              </TableCell>

                                              :

                                              column.label === 'Teléfono'  ?

                                                <TableCell align="center" key={i}>
                                                  <div >
                                                    {
                                                      row.telefono ?
                                                        row.telefono?.map((item,i) => {
                                                          return (

                                                            !item.numero || item.numero.length < 1 ?
                                                              <div key={i}>
                                                                -
                                                              </div>
                                                              :
                                                              <div key={i}>
                                                                <BsTelephoneFill className='text-morado me-1' />
                                                                {item.numero}
                                                              </div>

                                                          )
                                                        })
                                                        :
                                                        <div>
                                                          -
                                                        </div>
                                                    }
                                                  </div>
                                                </TableCell>

                                                :

                                                column.label === 'Marcación Corta' ?

                                                  <TableCell align="center" key={i}>
                                                    <div>

                                                      {
                                                        row.telefono ?
                                                          row.telefono?.map((item, i) => {
                                                            return (
                                                              !item.marcacion_corta || item.marcacion_corta.length < 1 ?
                                                                <div key={i}>
                                                                  -
                                                                </div>
                                                                :
                                                                <div key={i}>
                                                                  <BsTelephone className='text-morado me-1' />
                                                                  {item.marcacion_corta + ''}
                                                                </div>
                                                            )
                                                          })
                                                          :
                                                          <div>
                                                            -
                                                          </div>

                                                      }
                                                    </div>
                                                  </TableCell>

                                                  :

                                                  <TableCell key={i} className='cursor-pointer' align="center" onClick={() => {
                                                    setIdDep(row.id)
                                                    history.push(`/administradorOficinas/${row.id}`)
                                                  }} >
                                                    {column.format && typeof value === 'number'
                                                      ? column.format(value)
                                                      : value}
                                                  </TableCell>

                                    }
                                  </Fragment>

                                )

                              })}




                            </TableRow>
                          );
                        })}
                        {
                          modal2 === true ?
                            <PopUpEditDependency onclose={hiddenModal2}>
                            </PopUpEditDependency>
                            :
                            null
                        }

                      </TableBody>
                    </Table>

                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, { label: 'Todas', value: -1 }]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage='Filas por Página:'
                  />
                </div>

              </div>

            </div>
          </div>
        </div>
      }
    </>
  )
}