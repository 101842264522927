import React,{useState, useContext,useEffect} from 'react'
import {CSSTransition} from 'react-transition-group'
import { NotificacionContext } from '../../context/notificacion_context';
import {IoClose} from 'react-icons/io5'
import {IoIosWarning} from 'react-icons/io'
import {HiCheckCircle} from 'react-icons/hi'
import {MdError} from 'react-icons/md'
import {FaRegQuestionCircle} from 'react-icons/fa'

import IconUI from '../ui/icon_ui';
import { gray30, green, lightOrange , purple , red} from '../../assets/colores/colors';

function createMarkup(text) { return {__html: text}; };

export default function NotificationComponent(props) {

    const {notificacionState, showNotification,} = useContext(NotificacionContext)

    return (
  
        <div className="notificacions">
        {notificacionState.notifications.map((item, i)=>{
            return (
                <NotificacionItem key={i} data={item}/>
            )
        })}
        </div>
   
  
    )
}

function NotificacionItem  (props)  {

    const {closeNotification} = useContext(NotificacionContext)
    const [state, setState] = useState(props.data)


    useEffect(()=>{
        _showNotification()
    },[])

    const notificationType = (type) => {

        switch (type) {
            case "success":
                return (
                <IconUI color={green} size={24}>
                    <HiCheckCircle />
                </IconUI>
                );
            case "error":
                return (
                <IconUI color={red} size={24}>
                    <MdError />
                </IconUI>
                );
            case "warning":
                return (
                <IconUI color={lightOrange} size={24}>
                    <IoIosWarning />
                </IconUI>
                );
            case "option":
                return (
                <IconUI color={purple} size={24}>
                    <FaRegQuestionCircle />
                </IconUI>
                );
        
            default:
                return null
        }


    }


    const _closeNotification = () => {
        setState({...state, show:false})
    }
    const _showNotification = () => {
        setState({...state, show:true})
    }
    
    return(
        <CSSTransition
       
            in={state.show}
            timeout={200} 
            classNames="notification-animate"
            unmountOnExit
            onEnter={() => {
                
                if(state.time){
                    setTimeout(()=>{
                        _closeNotification(props.data)
                    }, state.time*1000)
                }
            }}
            onExited={() => {
                closeNotification(state)
            }}>
        <div className={`notificacion notificacion--${state.type}`}>

            {/* icon */}

            <div className="cursor-pointer mx-3">
               {notificationType(state.type)}
            </div>

            {/* titulo y el texto */}
           <div className='w-100'>
                <div className="text-bold">{state.title}</div>
                <div dangerouslySetInnerHTML={createMarkup(state.note)} className="text-small text-secondary"></div>

                {state.type === 'option'?
                    <div className='d-flex pb-2 mt-3'>
                        <div className='cursor-pointer text-small text-secondary' onClick={()=>{
                            _closeNotification();
                        }}>Cancelar</div>
                        <div className='cursor-pointer text-small text-bold text-blue px-3' onClick={()=>{
                            state.onContinue()
                            _closeNotification()
                        }}>Continuar</div>
                    </div>
                :null}
           </div>
           

          

            <div className="col"></div>

            {state.type !== 'option'?
            <div className="cursor-pointer ms-4 me-3" onClick={()=>{
                _closeNotification();
            }}>
                <IconUI color={gray30} size={24}>
                    <IoClose />
                </IconUI>
            </div>
            :null}

            

        </div>
        </CSSTransition>
    )

}
