import {  useHistory } from 'react-router-dom';
import LogoGris from '../assets/img/LogoGris.png'
import IconUI from '../components/ui/icon_ui'
import { BiLogOutCircle  } from 'react-icons/bi'
import { FaHome } from 'react-icons/fa'
import { MdOutlinePersonPin } from 'react-icons/md'
import OverLayIU from './ui/overlay_ui';



export function Menu({ onClose }) {
    const history = useHistory()
    
    return (
        <>
            <OverLayIU type={"transparent"} onClick={() => { onClose() }}>
            </OverLayIU>
            <div className="menu h-100 menuDerecho">
                <div className="brand  flex-column">
                    <img className="justify-content-start w-100" src={LogoGris}  />
                </div>


                <div className="menu_item w-100 d-flex flex-row justify-content-start align-items-center" onClick={() => {
                    history.push('/administradorDependencias');
                    onClose();

                }}>
                    <div className='p-2'>
                        <IconUI>
                            <FaHome />
                        </IconUI>
                    </div>
                    <div className='text-small'>
                        INICIO
                    </div>
                </div>

                <div className="menu_item w-100 d-flex flex-row justify-content-start align-items-center" onClick={() => {
                    history.push('/inicioFuncionario');
                    onClose();

                }}>
                    <div className='p-2'>
                        <IconUI>
                            <MdOutlinePersonPin />
                        </IconUI>
                    </div>
                    <div className='text-small'>
                        FUNCIONARIOS
                    </div>
                </div>




                <div className='col'></div>
                <div className='mb-3 d-flex justify-content-center'>
                    <div className='icon_btn' onClick={() => {
                        localStorage.removeItem("token");
                        localStorage.removeItem("nombre");
                        window.location.reload();
                    }}>
                        <IconUI size={30} >
                            <BiLogOutCircle />
                        </IconUI>
                        <div className='icon_btn_help' >Cerrar Sesión</div>
                    </div>
                </div>

            </div>

        </>
    )
}
