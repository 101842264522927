import React, { useContext, useEffect, useState, Fragment } from 'react';
import { BiSearch } from 'react-icons/bi';
import { BsTelephoneFill, BsTelephone } from 'react-icons/bs';
import { MdLocationPin } from 'react-icons/md';
import IconUI from '../components/ui/icon_ui'
import WEB from '../assets/img/card/WEB.svg'
import { GlobalContext } from '../context/global_context';
import { useHistory } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import { services } from '../services/api';
import GifLoader from "../components/ui/gifLoader"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';


export function DependenciesView() {

  const { setIdDep, palabra } = useContext(GlobalContext)
  const history = useHistory()

  const [state, setState] = useState({
    filtro: palabra,
    info: [],
    loading: false,
  })

  useEffect(async () => {

    let isMounted = true

    if (isMounted) {
      setState({ ...state, loading: true })
      let dataAPI = await services("GET", "publico/listarDependencias")

      if (dataAPI.status === "success") {
        setState({ ...state, info: dataAPI.data, loading: false })
      }
    }

    return () => {
      isMounted = false
    }
  }, [])

  const columns = [
    { id: 'siglas', label: 'Siglas', minWidth: 50 },
    { id: 'nombre', label: 'Dependencia', minWidth: 75 },
    { id: 'Telefono', label: 'Teléfono', minWidth: 100 },
    { id: 'Marcación', label: 'Marcación Corta', minWidth: 100 },
    { id: 'Web', label: 'Web', minWidth: 100 },
    { id: 'Localización', label: 'Localización', minWidth: 100 },
  ];

  const rows = state.info;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  const filter = () => {
    if (state.filtro === '') {
      return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).filter((item) => {
        return item.nombre.toLowerCase().includes(state.filtro.toLowerCase()) ||
          item.siglas.toLowerCase().includes(state.filtro.toLowerCase()) ||
          item.telefono[0].numero.toLowerCase().includes(state.filtro.toLowerCase()) ||
          item.telefono[0].marcacion_corta.filter((item2) => {
            return item2.includes(state.filtro.toLowerCase()) ?
              item2
              :
              false
          }).includes(state.filtro.toLowerCase())
      })
    }

    else {
      return rows.slice(page * rowsPerPage, rows.length).filter((item) => {
        return item.nombre.toLowerCase().includes(state.filtro.toLowerCase())
          || item.siglas && item.siglas.toLowerCase().includes(state.filtro.toLowerCase())
          || item.telefono && item.telefono[0].numero.toLowerCase().includes(state.filtro.toLowerCase())
          || item.telefono && item.telefono[1] && item.telefono[1].numero.toLowerCase().includes(state.filtro.toLowerCase())
          || item.telefono && item.telefono[2] && item.telefono[2].numero.toLowerCase().includes(state.filtro.toLowerCase())
          || item.telefono && item.telefono[0].marcacion_corta.filter((item2) => {
            return item2.includes(state.filtro.toLowerCase()) ?
              item2
              :
              false
          }).includes(state.filtro.toLowerCase())
      })
    }
  }



  return (
    <>
      {state.loading === true ?
        <GifLoader />
        :
        <div id="dependecy" className='dependency'>
          <div className="container text-center ">
            <div className="d-block justify-content-center">
              <div className="pt-3">
                <h2 className="text-center text-large text-secondary">
                  Bienvenido al Directorio Estatal de Servidores Públicos
                </h2>
              </div>
              <div className="py-4">
                <h6 className="text-center text-large text-tertiary">
                  Por favor ingresa nombre de la dependencia, siglas, teléfono o marcación corta
                </h6>
              </div>
              <div>
                <div className="d-block pb-4 justify-content-center ">

                  <Input
                    className='form-input text-center cursor-pointer'
                    endAdornment={<InputAdornment position="end">
                      <IconUI size={'31px'}>
                        <BiSearch />
                      </IconUI>
                    </InputAdornment>}
                    value={state.filtro ?? null} onChange={(e) => setState({ ...state, filtro: e.target.value })}
                    disableUnderline
                  />

                </div>
                {
                  filter().length <= 0 &&
                  <div className='w-100 justify-content-center d-flex'>
                    <div className='cta w-100 text-morado'>
                      <div>
                      { state.filtro.length>0 && (<>No se encontraron resultados para</>)}
                        <br />
                        {`${state.filtro.length > 30 ? state.filtro.slice(0, 30) : state.filtro}`}
                      </div>
                    </div>
                  </div>
                }
                <div>
                  <TableContainer >
                    <Table className='table table-striped'>
                      <TableHead>
                        <TableRow>
                          {columns && columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align="center"
                              style={{ top: 57, minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filter().map((row) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id} >
                              {columns && columns.map((column, i) => {
                                const value = row[column.id];
                                return (
                                  <Fragment key={i}>
                                    {
                                      column.label === 'Siglas' ?

                                        <TableCell key={i} className='cursor-pointer' align="center" onClick={() => {
                                          setIdDep(row.id)
                                          history.push('/dependencia')
                                        }}>
                                          <div >

                                            {
                                              row.siglas ?

                                                row.siglas
                                                :
                                                <div>
                                                  -
                                                </div>
                                            }
                                          </div>
                                        </TableCell>

                                        :

                                        column.label === 'Web' ?

                                          <TableCell key={i} align="center">
                                            <div className='cursor-pointer' onClick={() => {
                                              window.open(row.url_web);
                                            }}>
                                              <img src={WEB} style={{ height: 25, width: 25 }} alt="website logo" />
                                            </div>
                                          </TableCell>

                                          :

                                          column.label === 'Localización' ?

                                            <TableCell key={i} align="center">
                                              <div className='cursor-pointer' onClick={() => {
                                                window.open("https://maps.google.com?q=" + row.longitud + "," + row.latitud);
                                              }}>
                                                <MdLocationPin className='text-morado' style={{ height: 25, width: 25 }} />
                                              </div>
                                            </TableCell>

                                            :

                                            column.label === 'Teléfono' ?

                                              <TableCell key={i} align="center">
                                                <div >
                                                  <BsTelephoneFill className='text-morado me-1' />
                                                  {
                                                    row && row.telefono?.map((item) => {
                                                      return (
                                                        item.numero
                                                      )
                                                    })
                                                  }
                                                </div>
                                              </TableCell>

                                              :

                                              column.label === 'Marcación Corta' ?

                                                <TableCell key={i} align="center">
                                                  <div>

                                                    {
                                                      row && row.telefono && row.telefono.map((item, i) => {
                                                        return (
                                                          item.marcacion_corta.length < 1 ?
                                                            <div key={i}>
                                                              -
                                                            </div>
                                                            :
                                                            <div key={i}>
                                                              <BsTelephone className='text-morado me-1' />
                                                              {item.marcacion_corta + ''}
                                                            </div>
                                                        )
                                                      })
                                                    }
                                                  </div>
                                                </TableCell>

                                                :

                                                <TableCell key={i} className='cursor-pointer' align="center" onClick={() => {
                                                  setIdDep(row.id)
                                                  history.push(`/dependencia/${row.id}`)
                                                }} >
                                                  {column.format && typeof value === 'number'
                                                    ? column.format(value)
                                                    : value}
                                                </TableCell>

                                    }
                                  </Fragment>
                                )

                              })}

                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, { label: 'Todas', value: -1 }]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage='Filas por Página:'
                  />
                </div>

              </div>

            </div>
          </div>
        </div>
      }
    </>
  )
}

